<template>
  <div
    class="status-tag"
    :style="[
      { color: status?.color },
      { backgroundColor: status?.backgroundColor },
      { ...statusToColorMap()(status.name) },
    ]"
  >
    {{ status.label }}
  </div>
</template>
<script setup>
const props = defineProps({
  status: {
    type: Object,
    required: true,
  },
});

const statusToColorMap = () => (statusName) => {
  if (statusName === "in_work")
    return { color: "#0D5FCB", backgroundColor: "#0962A91A" };
  if (
    statusName?.trim()?.toLowerCase() === "declined" ||
    statusName?.trim()?.toLowerCase() === "отказался" ||
    statusName?.trim()?.toLowerCase() === "отказ"
  )
    return { color: "#DD4646", backgroundColor: "#DD46461A" };
  if (statusName === "default")
    return { color: "#8F9CAC", backgroundColor: "#F4F5F7" };
  return { color: "#0D5FCB", backgroundColor: "#0962A91A" };
};
</script>

<style lang="scss" scoped>
.status-tag {
  display: inline-block;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: left;
}
</style>
