<template>
  <div class="candidate-import">
    <div class="candidate-import__close" @click.prevent="$emit('close')">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15 5L5 15"
          stroke="#333333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5 5L15 15"
          stroke="#333333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <img
      class="candidate-import__icon"
      src="@/assets/images/icons/file-text.svg"
    />
    <h2 class="candidate-import__title">
      Найдено
      {{ data?.new_candidates_count + data?.duplicated_candidates_count }}
      кандидатов
    </h2>
    <p class="candidate-import__description">
      {{ data?.new_candidates_count }} новые /
      {{ data?.duplicated_candidates_count }} дубли
    </p>
    <VButton
      label="Сохранить с заменой"
      bg="#0D5FCB"
      color="#fff"
      class="candidate-import__button"
      @click="saveAll"
    />
    <VButton
      label="Сохранить только новые"
      bg="#0D5FCB"
      color="#fff"
      class="candidate-import__button_transparent"
      @click="saveOnlyNew"
    />
    <VButton
      label="Отменить"
      bg="#E4E7EE"
      bgHover="#BBC4CD"
      color="#1E3959"
      colorHover="#1E3959"
      class="candidate-import__button"
      @click="$emit('close')"
    />
  </div>
</template>
<script setup>
import { ref } from "vue";
const emit = defineEmits(["close", "saveAll", "saveOnlyNew"]);
const props = defineProps({
  data: {
    type: Object,
    required: false,
  },
});

const saveAll = () => {
  emit("saveAll");
};

const saveOnlyNew = () => {
  emit("saveOnlyNew");
};
</script>
<style lang="scss" scoped>
.candidate-import {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 40px 24px 24px;

  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }

  &__icon {
    height: 56px;
    width: 56px;
    flex: 0 0 auto;
    align-self: center;
    margin-bottom: 24px;
  }

  &__title {
    margin-bottom: 12px;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    line-height: 24.2px;
  }

  &__description {
    margin-bottom: 24px;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.8px;
  }
  &__button {
    &:not(:last-child) {
      margin-bottom: 12px;
    }
    &_transparent {
      color: $blue !important;
      background-color: transparent !important;
      border: 1px solid $blue !important;
      margin-bottom: 12px;
    }
  }
}
</style>
