<template>
  <div class="v-select" :class="`v-select_${id}`">
    <div
      class="v-select__header"
      :class="{
        'v-select__header_no-placeholder': !placeholder,
        'v-select__header_active': showOptions,
        'v-select__header_selected': inputVal.length,
        'v-select__header_error validation-error': error,
        'v-select__header_disabled': disabled,
      }"
      @click.prevent="openOptions"
    >
      <p class="v-select__header-palceholder">{{ placeholder }}</p>
      <template v-if="isTagsLike">
        <p class="v-select__header-value_tags" v-if="inputVal?.length">
        <div class="v-select__header-item_tag" v-for="(val, index) in selected(inputVal)" :key="index">{{ val }}<img class="v-select__header-item-crossmark" src="@/assets/images/icons/x-small-gray.svg" @click.stop="(index) => removeVal(index)" /> &nbsp;</div>
      </p>
      </template>
      <template v-else>
        <p class="v-select__header-value" v-if="inputVal?.length">
        <div class="v-select__header-item" v-for="(val, index) in selected(inputVal)" :key="index">{{ val }}{{ index + 1 < inputVal?.length ? ',' : '' }}</div>
      </p>
      </template>
      <span 
      v-if="isWithoutCheckmarks" 
      class="v-select__header-reset" 
      @click.stop="resetChosen"><img class="v-select__header-reset-img" width="6" height="6" src="@/assets/images/icons/x-small-white.svg"/></span>

      <img
        v-if="!disabled"
        src="@/assets/images/icons/arrow-down.svg"
        class="v-select__header-arrow"
        alt="arrow"
      />
      <img
        v-else
        src="@/assets/images/icons/lock.svg"
        class="v-select__header-lock"
        alt="arrow"
      />
    </div>
    <div
      ref="optionsElem"
      v-if="showOptions && !disabled"
      class="v-select__drop-menu"
      :class="{ 'v-select__options_top': topPositonOptions }"
    >
      <div v-if="isWithReset" class="v-select__options-header">
        <div class="v-select__options-header-label">{{ label }}</div>
        <div class="v-select__options-reset" @click="resetChosen">Сбросить</div>
      </div>
      <section class="v-select__options-list">
        <label
          :class="['v-select__option-label',{
            'v-select__option-checkbox_active': inputVal.includes(option.id),
          },{'v-select__option-label_no-checkmarks': isWithoutCheckmarks}]"
          v-show="!empty && option[labelField]?.length"
          v-for="option in options"
          :key="option.id"

        >
          <input
            type="checkbox"
            class="v-select__option-input"
            @click="selectCheckbox(option.id)"
          />
          <span v-if="!isWithoutCheckmarks" :class="['v-select__option-checkmark',{
            'v-select__option-checkmark_checked': inputVal.includes(option.id),
          }]"></span>
          {{ option[labelField] }}
          {{ option[subTitleField] ? `(${option[subTitleField]})` : "" }}
        </label>
      </section>

      <div class="v-select__option v-select__option_empty" v-show="empty">
        {{ emptyText }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
const props = defineProps({
  modelValue: {
    type: Array,
    default: []
  },
  placeholder: String,
  options: Array,
  disabled: {
    type: Boolean,
    default: false,
  },
  error: {
    type: Boolean,
    default: false,
  },
  id: Number,
  labelField: {
    type: String,
    default: "label",
  },
  label: {
    type: String,
  },
  subTitleField: {
    type: String,
    default: "",
  },
  empty: {
    type: Boolean,
    default: false,
  },
  emptyText: {
    type: String,
    default: "Пусто",
  },
  noHideSelected: {
    type: Boolean,
    default: false,
  },
  isWithReset: {
    type: Boolean,
    default: false,
  },
  isWithoutCheckmarks: {
    type: Boolean,
    default: false,
  },
  isTagsLike: {
    type: Boolean,
    default: false,
  }
});

const emit = defineEmits(["update:modelValue"]);

const inputVal = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
});

const selected = computed(() => {
  return (arr) =>
    arr.map((val) => {
      return props.options.find((item) => item?.id === val)?.[props.labelField];
    });
});

const showOptions = ref(false);

const selectCheckbox = (val) => {
  if(!Array.isArray(inputVal.value)) {
    inputVal.value = []
  }
  const findedIndex = inputVal.value.findIndex((item) => item === val);
  if (findedIndex >= 0) {
    inputVal.value.splice(findedIndex, 1);
  } else {
    inputVal.value.push(val);
  }
};

document.addEventListener("click", (e) => {
  if (!e.target.closest(`.v-select_${props.id}`)) showOptions.value = false;
});

const optionsElem = ref(null);
const topPositonOptions = ref(false);

const isElementTouchingBottom = (element) => {
  const elementRect = element.getBoundingClientRect();
  const windowHeight = window.innerHeight;
  const documentHeight = document.documentElement.scrollHeight;
  const elementOffsetTop = elementRect.top + window.scrollY;
  const elementHeight = elementRect.height;

  return (
    elementOffsetTop + elementHeight >= windowHeight &&
    elementOffsetTop + elementHeight >= documentHeight
  );
};

const openOptions = () => {
  showOptions.value = !showOptions.value;
  if (!showOptions.value) return;
  setTimeout(() => {
    const isTouchingBottom = isElementTouchingBottom(optionsElem.value);

    if (isTouchingBottom) topPositonOptions.value = true;
  }, 0);
};

const resetChosen = () => {
  inputVal.value = [];
};

const removeVal = (index) => {
    inputVal.value.splice(index,1)
}
onMounted(() => {
  if(!inputVal.value){
    inputVal.value = []
  }
})
</script>

<style scoped lang="scss">
.v-select {
  position: relative;
  user-select: none;

  &__header {
    position: relative;
    width: 100%;
    height: 48px;
    padding: 0 8px 0 16px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: $light;
    cursor: pointer;
  }

  &__header_disabled {
    cursor: default;
  }

  &__header_error {
    border: 1px solid $red;
  }

  &__header-palceholder {
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
    color: $dark-blue;
    font-size: 14px;
    transition: 0.3s;
  }

  &__header_selected &__header-palceholder {
    font-size: 12px;
    top: 6px;
    transform: translateY(0%);
    color: $dark-blue;
  }

  &__header-value {
      display: inline-block;
      position: absolute;
      left: 16px;
      top: 25px;
    &_tags {
      display: inline-block;
      position: absolute;
      left: 16px;
      top: 25px;
      font-size: 14px;
    }
  }

  &__header-item {
    position: relative;
    display: inline-block;
    font-size: 14px;
    line-height: 16.94px;

    &_tag {
      position: relative;
      padding: 2px 22px 2px 6px;
      display: inline-block;
      border: 1px solid #566A83;
      border-radius: 4px;
      font-size: 12px;
      font-weight: 400;
      line-height: 14.52px;
      text-align: left;
    }

    &:not(:last-child){
        margin-right: 8px;
    }
  }

  &__header-item-crossmark {
    display: flex;
    position: absolute;
    z-index: +1;
    right: 5px;
    top: 5px;
    width: 9px;
    height: 9px;
    cursor: pointer;
    object-fit: contain;
  }

  &__header_no-placeholder &__header-value {
    top: 15px;
  }

  &__header-reset {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: +1;
    right: 48px;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background-color: #566A83;
    color: #fff;

  }

  &__header-arrow {
    width: 20px;
    height: 20px;
    transition: 0.3s;
  }

  &__header_active &__header-arrow {
    transform: rotate(-180deg);
  }

  &__header-lock {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  &__options-header {
    padding-left: 16px;
    padding-top: 12px;
    padding-right: 8px;
    padding-bottom: 12px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #f4f5f7;
  }

  &__options-list {
    padding: 4px 8px;
    overflow-y: auto;
    scrollbar-width: thin;
    max-height: calc(45px * 5);
  }

  &__options-header-label {
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    text-align: left;
  }

  &__options-reset {
    color: $red;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.52px;
    text-align: left;
    cursor: pointer;
  }

  &__drop-menu {
    position: absolute;
    top: 56px;
    left: 0;
    width: 100%;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 2px 4px 24px rgba(0, 0, 0, 0.12);
    border: 1px solid $light-gray-1;
    z-index: 90;

    &_top {
      top: auto;
      bottom: 56px;
    }
  }

  &__option {
    width: 100%;
    min-height: 40px;
    max-height: 52px;
    padding: 12px 8px;
    display: flex;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    text-align: left;
    line-height: 16.94px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    &:hover {
      background-color: $light-gray-1;
      color: $dark;
      border-radius: 8px;
    }

    &_empty {
      color: $gray;
      text-align: left;
      font-size: 14px;
      cursor: default;

      &:hover {
        background-color: transparent;
        color: $gray;
      }
    }
  }
  &__option-label {
    width: 100%;
    min-height: 40px;
    max-height: 52px;
    padding: 12px 8px 12px 34px;
    display: flex;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    text-align: left;
    line-height: 16.94px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    position: relative;

    &_no-checkmarks {
        display: flex;
        padding: 8px;
    }

    &:hover {
      background-color: $light-gray-1;
      color: $dark;
      border-radius: 8px;
    }

    &_empty {
      color: $gray;
      text-align: left;
      font-size: 14px;
      cursor: default;

      &:hover {
        background-color: transparent;
        color: $gray;
      }
    }
  }

  &__option-input {
    appearance: none;
    position: absolute;
  }
  &__option-checkmark {
    display: inline-block;
    position: absolute;
    top: 12px;
    left: 8px;
    height: 18px;
    width: 18px;
        border: 1px solid #BBC4CD;
    border-radius: 4px;
    &_checked {
        background-image: url("/img/icons/checkmark-blue.svg");
        background-repeat: no-repeat;
        background-size: 12px;
        background-position-y: center;
        background-position-x: center;
    }
  }
}
</style>
