<template>
  <div class="v-editor" :class="{ 'v-editor_error validation-error': error }">
    <span
      class="v-editor__placeholder"
      :class="{ 'v-editor__placeholder_focus': focus || inputVal }"
      >{{ placeholder }}
    </span>
    <VueEditor
      class="v-editor__editor"
      id="editor1"
      v-model="inputVal"
      :editorOptions="editorSettings"
      :editorToolbar="customToolbar"
      :maxlength="maxlength"
      @focus="focus = true"
      @blur="blur"
    />
  </div>
</template>

<script setup>
import { VueEditor } from "vue3-editor";
import { computed, ref } from "vue";

const props = defineProps({
  modelValue: [String, Number],
  placeholder: {
    type: String,
  },
  error: {
    type: Boolean,
    default: false,
  },
  maxlength: Number,
});

const emit = defineEmits(["update:modelValue"]);

const inputVal = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
});

const focus = ref(false);

const editorSettings = {};
const customToolbar = [
  ["bold", "italic", { list: "bullet" }, { list: "ordered" }],
];

const removeHTMLTags = (str) => {
  return str.replace(/<[^>]*>/g, "");
};

const blur = () => {
  focus.value = false;

  if (!removeHTMLTags(inputVal.value).trim().length) {
    inputVal.value = "";
  }
};
</script>

<style scoped lang="scss">
.v-editor {
  position: relative;
  border-radius: 8px;

  &_error {
    border: 1px solid $red !important;
  }

  &__placeholder {
    position: absolute;
    top: 56px;
    left: 16px;
    background-color: $light;
    font-weight: 400;
    transition: 0.3s;
    color: $gray;
    font-size: 12px;
  }

  &__editor {
    height: 216px;
    border-radius: 8px;
    overflow: hidden;
    background-color: $light;

    &::v-deep(.ql-toolbar.ql-snow) {
      position: sticky;
      top: 12px;
      left: 16px;
      width: 160px;
      height: 32px;
      padding: 0 !important;
      border-radius: 4px;
      display: flex;
      align-items: center;
      background-color: #fff;
      border: none;

      .ql-formats {
        min-width: 100%;
        height: 32px;
        margin-top: 9px;
        padding-left: 7px;
        padding-right: 3px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        button {
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;

          svg {
            display: none;
          }
        }

        .ql-bold {
          background-image: url("../../../assets/images/icons/editor-bold.svg");
        }

        .ql-active.ql-bold {
          background-image: url("../../../assets/images/icons/editor-bold-white.svg");
        }

        .ql-italic {
          background-image: url("../../../assets/images/icons/editor-italic.svg");
        }

        .ql-active.ql-italic {
          background-image: url("../../../assets/images/icons/editor-italic-white.svg");
        }

        .ql-list {
          background-image: url("../../../assets/images/icons/editor-list.svg");
        }

        .ql-active.ql-list {
          background-image: url("../../../assets/images/icons/editor-list-white.svg");
        }

        .ql-list[value="ordered"] {
          background-image: url("../../../assets/images/icons/editor-list-num.svg");
        }

        .ql-active.ql-list[value="ordered"] {
          background-image: url("../../../assets/images/icons/editor-list-num-white.svg");
        }

        .ql-active {
          border-radius: 4px;
          background-color: $blue;
        }
      }
    }

    &::v-deep(.ql-container) {
      margin-top: 23px;
      height: calc(100% - 55px);
      padding-top: 15px;
      border: none;

      .ql-editor {
        min-height: 100%;
        padding-top: 4px;
        font-size: 14px;

        ul,
        ol {
          padding-left: 0;
        }

        ul li {
          margin-left: 16px;
          &:before {
            content: "";
            width: 4.4px;
            height: 4.5px;
            margin-bottom: 3px;
            border-radius: 10px;
            background-color: #000;
          }
        }

        li {
          padding-left: 13px;

          &:before {
            margin-right: 6px;
          }
        }

        ol li {
          padding-left: 20px;
        }

        &::-webkit-scrollbar {
          width: 12px;
          border: 4px solid $light;
          background-color: $light-gray-1;
        }

        /* ползунок скроллбара */
        &::-webkit-scrollbar-thumb {
          background-color: $light-gray-2;
          border: 4px solid $light;
          border-radius: 8px;
        }
      }
    }

    &::v-deep(.ql-formats) {
      button {
        border-radius: 4px;

        &:hover {
          background-color: $light;
        }
      }
    }
  }
}
</style>
