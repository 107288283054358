<template>
  <div class="header-sort">
    <template v-for="(field, index) in scheme" :key="index">
      <div
        :class="[
          'header-sort__item',
          { 'header-sort__item_clickable': field.sortable },
          { 'header-sort__item_active': !!selectedSort[field.value] },
          {
            'header-sort__item-sorted_asc':
              selectedSort[field.value] === `${field.value}`,
          },
          {
            'header-sort__item-sorted_desc':
              selectedSort[field.value] === `-${field.value}`,
          },
        ]"
        :style="{ width: `${field.width}px` }"
        @click.prevent.stop="selectSort(field.value)"
      >
        <span :class="['header-sort__item-text']">{{ field.label }}</span>
        <div class="header-sort__item-icons" v-if="field.sortable">
          <span class="header-sort__item-sort_asc">
            <svg fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9.75 5.66406L5.5 1.41406L1.25 5.66406"
                stroke="#8F9CAC"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <span class="header-sort__item-sort_desc">
            <svg fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1.25 1.33203L5.5 5.58203L9.75 1.33203"
                stroke="#8F9CAC"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
import { ref } from "vue";
const emit = defineEmits(["update:modelValue"]);
const props = defineProps({
  modelValue: {
    type: [Object, String],
  },
  scheme: {
    type: [Object, Array],
  },
});

const mapedSchemeValues = {};
Object.keys(props.scheme).forEach(
  (key) => (mapedSchemeValues[props.scheme[key].value] = "")
);
const selectedSort = ref(mapedSchemeValues);

const cachedField = ref("");

const selectSort = (field) => {
  if (!cachedField.value) {
    cachedField.value = field;
  }
  if (cachedField.value !== field) {
    cachedField.value = field;
    Object.keys(selectedSort.value).forEach((key) => {
      selectedSort.value[key] = "";
    });
  }
  if (!selectedSort.value[field]) {
    selectedSort.value[field] = `${field}`;
  } else if (selectedSort.value[field] === `${field}`) {
    selectedSort.value[field] = `-${field}`;
  } else if (selectedSort.value[field] === `-${field}`) {
    selectedSort.value[field] = "";
  }
  const emitableObject = { [field]: selectedSort.value[field] };
  const emitableSortString = `sort=${selectedSort.value[field]}`;
  emit("update:modelValue", emitableSortString);
};
</script>

<style scoped lang="scss">
.header-sort {
  width: 100%;
  height: 40px;
  padding-left: 24px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 24px;
  background-color: #fff;
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.04);

  &__item {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: $dark;
    pointer-events: none;
    user-select: none;

    &:not(:first-child) {
      font-weight: 400;
    }

    &_clickable {
      cursor: pointer;
      pointer-events: all;
      user-select: all;
    }

    &_active {
      opacity: 1;
    }
  }

  &__item-text {
    font-weight: 400;
    user-select: none;
  }

  &__item-icons {
    display: flex;
    flex-direction: column;
    margin-left: 6px;
    padding: 1px 3px;
    width: 17px;
    height: 17px;
  }

  &__item-sort {
    &_asc {
      display: block;
      height: 7px;

      margin-bottom: 3px;
      flex-shrink: 0;
      overflow: hidden;

      & > svg {
        width: 11px;
        max-height: 20px;
      }

      & > svg path {
        stroke: #8f9cac;
      }
    }

    &_desc {
      display: block;
      height: 7px;
      flex-shrink: 0;
      overflow: hidden;

      & > svg {
        width: 11px;
        max-height: 20px;
      }

      & > svg path {
        stroke: #8f9cac;
      }
    }
  }

  &__item-sorted {
    &_asc {
      & .header-sort__item-sort_asc > svg path {
        stroke: #1e3959;
      }
    }

    &_desc {
      & .header-sort__item-sort_desc > svg path {
        stroke: #1e3959;
      }
    }
  }
}
</style>
