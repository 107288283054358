import api from "./api";

const formDataOptions = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};
const candidates = {
  getFilters() {
    return api.get(`/candidates/references/filters`);
  },
  getStatuses() {
    return api.get(`/candidates/references/statuses`);
  },
  getSources() {
    return api.get(`/candidates/references/sources`);
  },
  getSpecialities() {
    return api.get(`/candidates/references/specialities`);
  },
  getLabels() {
    return api.get(`/candidates/references/labels`);
  },
  getVacancies(search) {
    return api.get(`/candidates/?vacancy_id=${search}`);
  },
  getDublicate(id) {
    return api.get(`/candidates/${id}/duplicate`);
  },
  deleteDublicate(id) {
    return api.delete(`/candidates/${id}/duplicate`);
  },
  updateDublicate(id, body) {
    return api.post(`/candidates/${id}/duplicate`, body);
  },
  replaceFromDublicate(id) {
    return api.post(`/candidates/${id}/replace-from-duplicate `);
  },
  addCandidate(body) {
    return api.post(`/candidates/`, body);
  },
  updateCandidate(id, body) {
    return api.put(`/candidates/${id}`, body);
  },
  importExcel(body) {
    const formData = new FormData();
    formData.append("file", body);
    return api.post(
      `/candidates/check-excel-import`,
      formData,
      formDataOptions
    );
  },
  saveImport(body) {
    const formData = new FormData();
    formData.append("file", body);
    return api.post(
      `/candidates/excel-import?mode=all`,
      formData,
      formDataOptions
    );
  },
  saveOnlyNew(body) {
    const formData = new FormData();
    formData.append("file", body);
    return api.post(
      `/candidates/excel-import?mode=new`,
      formData,
      formDataOptions
    );
  },
  getCandidates(query = "", additionalStringQuery) {
    if (additionalStringQuery) {
      return api.get(`/candidates?${additionalStringQuery}`, { params: query });
    } else {
      return api.get(`/candidates`, { params: query });
    }
  },
  getCandidate(id) {
    return api.get(`/candidates/${id}`);
  },
  postStatus(candidateId, statusId, body) {
    return api.post(`/candidates/${candidateId}/setStatus/${statusId}`, body);
  },
  getHistory(id) {
    return api.get(`/candidates/${id}/history`);
  },
  transferDecision(id, body) {
    return api.post(`/candidates/${id}/delayDeadline`, body);
  },
};

export default candidates;
