<template>
  <div :class="['list-card']">
    <div
      v-for="(field, index) in schemeFields"
      :key="field.key"
      :class="['list-card__item', field?.class]"
      :style="{ width: `${field.width}px` }"
    >
      <slot
        :name="`field(${field.key})`"
        :cardData="{
          data: props?.cardData,
          fieldData: props?.cardData?.[field?.key],
          index,
        }"
      >
        <div v-if="props.cardData?.[field.key] || field?.class !== 'hide'">
          {{ props?.cardData?.[field?.key] }}
        </div>
      </slot>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { useRoute } from "vue-router";

const emit = defineEmits(["openChat"]);

const route = useRoute();

const props = defineProps({
  cardData: {
    type: Object,
    required: true,
  },
  scheme: {
    type: [Object, Array],
    required: true,
  },
});

const role = route.meta.role;
const rolePath = ref("/responses");
const schemeFields = {};

Object.keys(props.scheme).forEach(
  (key) => (schemeFields[key] = props.scheme?.[key])
);
const colorsClassMap = computed(() => (cardData) => {
  if (cardData?.["is_new"]) {
    return "color_blue";
  } else if (cardData?.["is_deadline"]) {
    return "color_red";
  } else if (cardData?.["is_closed"]) {
    return "color_none";
  }
  return "color_gray";
});

if (role === "SECURITY_SERVICE") {
  rolePath.value = "/sb-tasks";
}

if (role === "DIRECTION_LEADER") {
  rolePath.value = "/dl-tasks";
}
</script>

<style scoped lang="scss">
.list-card {
  position: relative;
  width: 100%;
  height: 68px;
  padding-left: 24px;
  padding-right: 16px;
  border-radius: 16px;
  display: flex;
  gap: 24px;
  align-items: center;
  background-color: #fff;
  color: $dark;
  text-decoration: none;
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.04);
  cursor: pointer;

  &__item {
    min-width: 60px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    flex: 0 0 auto;
  }

  &__title {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 14px;
    font-weight: 500;
  }

  &__day {
    color: $gray;
    font-size: 14px;
    font-weight: 400;
  }

  &__name {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 14px;
    font-weight: 400;
  }

  &__people {
    display: flex;
    flex-direction: column;

    div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
      font-weight: 400;
    }

    p {
      margin-top: 2px;
      color: $gray;
      font-size: 14px;
      font-weight: 400;
    }
  }

  &__city {
    font-size: 14px;
    font-weight: 400;
  }
  &__experience {
    font-weight: 400;
  }

  &__btns {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__btn {
    position: relative;
    width: 32px;
    height: 32px;
    border-radius: 6px;
    background-color: $light;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 20px;
    }

    &_new-message {
      &::after {
        content: " ";
        position: absolute;
        top: 6px;
        right: 6px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        z-index: +1;
        background-color: $red;
      }
    }
  }
}

.color {
  &_blue {
    --color: #0166b8;
  }
  &_red {
    --color: #dd4646;
  }
  &_gray {
    --color: #8f9cac;
  }
}

.hide {
  display: none;
}
.split {
  &__horizontal {
    display: flex;
    flex-direction: column;
  }
}
</style>
