<template>
    <div class="v-select" :class="`v-select_${id}`">
        <div
            class="v-select__header"
            :class="{
          'v-select__header_no-placeholder': !placeholder,
        'v-select__header_active': showOptions,
        'v-select__header_selected': inputVal,
        'v-select__header_error validation-error': error,
        'v-select__header_disabled': disabled}"
            @click="openOptions">
            <p class="v-select__header-palceholder">{{ placeholder }}</p>
            <p class="v-select__header-value" v-if="inputVal && labelFild">{{ selected(inputVal)?.[labelFild] }} {{selected(inputVal)?.[subTitleFild] ? `(${selected(inputVal)?.[subTitleFild]})` : ''}}</p>
            <img v-if="!disabled" src="@/assets/images/icons/arrow-down.svg" class="v-select__header-arrow" alt="arrow">
            <img v-else src="@/assets/images/icons/lock.svg" class="v-select__header-lock" alt="arrow">
        </div>
        <div
            ref="optionsElem"
            v-if="showOptions && !disabled"
            class="v-select__options"
            :class="{'v-select__options_top': topPositonOptions}"
        >
            <div
                class="v-select__option"
                v-show="!empty && option[labelFild]?.length"
                v-for="option in options"
                :key="option.id"
                :class="{'v-select__option_active': option.id === inputVal}"
                @click="select(option.id)">
                {{ option[labelFild] }}
               {{ option[subTitleFild] ? `(${option[subTitleFild]})` : '' }}
            </div>
            <div
                class="v-select__option v-select__option_empty"
                v-show="empty">{{ emptyText }}
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref, computed} from "vue";

const props = defineProps({
    modelValue: [String, Number, Boolean],
    placeholder: String,
    options: Array,
    disabled: {
        type: Boolean,
        default: false
    },
    error: {
        type: Boolean,
        default: false,
    },
    id: Number,
    labelFild: {
        type: String,
        default: 'label'
    },
    subTitleFild: {
        type: String,
        default: ''
    },
    empty: {
        type: Boolean,
        default: false
    },
    emptyText: {
        type: String,
        default: 'Пусто'
    },
    noHideSelected: {
        type: Boolean,
        default: false
    }
})

const emit = defineEmits(["update:modelValue"]);

const inputVal = computed({
    get: () => props.modelValue,
    set: (value) => emit("update:modelValue", value),
});

const selected = computed(() => {
    return val => props.options.find(item => item?.id === val)
})

const showOptions = ref(false)

const select = (val) => {
    inputVal.value = val
    if(!props.noHideSelected) showOptions.value = false
}

document.addEventListener('click', (e) => {
    if (!e.target.closest(`.v-select_${props.id}`)) showOptions.value = false
})

const optionsElem = ref(null)
const topPositonOptions = ref(false)

const isElementTouchingBottom = (element) => {
    const elementRect = element.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const elementOffsetTop = elementRect.top + window.scrollY;
    const elementHeight = elementRect.height;

    return elementOffsetTop + elementHeight >= windowHeight && elementOffsetTop + elementHeight >= documentHeight;
}

const openOptions = () => {
    showOptions.value = !showOptions.value
    if (!showOptions.value) return
    setTimeout(() => {
        const isTouchingBottom = isElementTouchingBottom(optionsElem.value);

        if (isTouchingBottom) topPositonOptions.value = true
    }, 0)
}
</script>

<style scoped lang="scss">
.v-select {
    position: relative;
    user-select: none;

    &__header {
        position: relative;
        width: 100%;
        height: 48px;
        padding: 0 8px 0 16px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        background-color: $light;
        cursor: pointer;
    }

    &__header_disabled {
        cursor: default;
    }

    &__header_error {
        border: 1px solid $red;
    }

    &__header-palceholder {
        position: absolute;
        left: 16px;
        top: 50%;
        transform: translateY(-50%);
        color: $dark-blue;
        font-size: 14px;
        transition: .3s;
    }

    &__header_selected &__header-palceholder {
        font-size: 12px;
        top: 6px;
        transform: translateY(0%);
        color: $dark-blue;
    }

    &__header-value {
        position: absolute;
        left: 16px;
        top: 25px;
        font-size: 14px;
    }

    &__header_no-placeholder &__header-value {
        top: 15px;
    }

    &__header-arrow {
        width: 20px;
        height: 20px;
        transition: .3s;
    }

    &__header_active &__header-arrow {
        transform: rotate(-180deg);
    }

    &__header-lock {
        width: 20px;
        height: 20px;
        margin-right: 8px;
    }

    &__options {
        position: absolute;
        top: 56px;
        left: 0;
        width: 100%;
        padding: 8px;
        border-radius: 8px;
        background-color: #FFFFFF;
        box-shadow: 2px 4px 24px rgba(0, 0, 0, 0.12);
        border: 1px solid $light-gray-1;
        z-index: 90;
        max-height: calc(42px * 5);
        overflow-y: auto;
        scrollbar-width: thin;

        &_top {
            top: auto;
            bottom: 56px;
        }
    }

    &__option {
        width: 100%;
        min-height: 40px;
        max-height: 52px;
        padding: 12px 8px;
        display: flex;
        align-items: center;
        font-size: 14px;
        cursor: pointer;
        text-align: left;
        line-height: 16.94px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;

        &:hover {
            background-color: $light-gray-1;
            color: $dark;
            border-radius: 8px;
        }

        &_empty {
            color: $gray;
            text-align: left;
            font-size: 14px;
            cursor: default;

            &:hover {
                background-color: transparent;
                color: $gray;
            }
        }
    }
}
</style>
