<template>
  <div class="candidate-sidebar">
    <div
      :class="[
        'candidate-sidebar__wrapper',
        { 'candidate-sidebar__wrapper_bordered': role === 'APPROVING_PERSON' },
      ]"
    >
      <div
        :class="['candidate-sidebar__head', { head_detached: isHeadDetached }]"
      >
        <div class="candidate-sidebar__title">{{ title }}</div>
        <VSearchSelect
          :id="1"
          class="candidate-sidebar__select"
          placeholder="Введите название вакансии"
          :items="filteredVacancy"
          @selectItem="onSelectVacancy"
          @input="debouncedSearchVacancy"
          v-model="searchVacancyValue"
        />
      </div>
      <div :class="['candidate-sidebar__main']">
        <div
          v-if="role !== 'APPROVING_PERSON'"
          class="candidate-sidebar__candidate-title"
        >
          {{ subtitle }}
        </div>
        <div class="candidate-sidebar__field-wrapper">
          <div class="candidate-sidebar__field-label">Статус</div>
          <div class="candidate-sidebar__field-row">
            <VMultiSelect
              class="candidate-sidebar__select"
              placeholder="Выберите статус"
              label="Статус"
              :id="2"
              :options="filterOptions?.status"
              :isWithReset="true"
              v-model="selectedFilters.status"
            />
          </div>
        </div>
        <div class="candidate-sidebar__field-wrapper">
          <div class="candidate-sidebar__field-label">Возраст</div>
          <div class="candidate-sidebar__field-row">
            <VCustomInput
              class="candidate-sidebar__select"
              :options="arrayTypedVacancies"
              placeholder="От"
              v-model="selectedFilters.age_from"
            />
            <VCustomInput
              class="candidate-sidebar__select"
              placeholder="До"
              :options="arrayTypedVacancies"
              v-model="selectedFilters.age_to"
            />
          </div>
        </div>
        <div class="candidate-sidebar__field-wrapper">
          <div class="candidate-sidebar__field-label">Город</div>
          <div class="candidate-sidebar__field-row">
            <VSearchSelect
              class="candidate-sidebar__select"
              placeholder="Выберите город"
              :items="resultsResidence"
              @selectItem="onSelectResidence"
              @input="debouncedSearchResidence"
              @blur="onSearchResidenceBlur"
              v-model="searchResidenceValue"
            />
          </div>
        </div>
        <div class="candidate-sidebar__field-wrapper">
          <div class="candidate-sidebar__field-label">
            Должность {{ specialities }}
          </div>
          <div class="candidate-sidebar__field-row">
            <VMultiSelect
              class="candidate-sidebar__select"
              placeholder="Выберите источник"
              label="Источник"
              :id="4"
              :options="filterOptions?.specialities"
              :isWithReset="true"
              @update:modelValue="onVacancyTypeSelect"
              v-model="selectedFilters.specialities"
            />
          </div>
        </div>
        <div class="candidate-sidebar__field-wrapper">
          <div class="candidate-sidebar__field-label">Опыт работы</div>
          <div class="candidate-sidebar__field-row">
            <VCustomInput
              class="candidate-sidebar__select"
              placeholder="От"
              :options="arrayTypedVacancies"
              @update:modelValue="onExperienceFrom"
              v-model="selectedFilters.experience_from"
            />
            <VCustomInput
              class="candidate-sidebar__select"
              placeholder="До"
              :options="arrayTypedVacancies"
              @update:modelValue="onExperienceTo"
              v-model="selectedFilters.experience_to"
            />
          </div>
        </div>
        <div class="candidate-sidebar__field-wrapper">
          <div class="candidate-sidebar__field-label">Дата добавления</div>
          <div class="candidate-sidebar__field-row">
            <div class="candidate-sidebar__date-label">От</div>
            <VDate
              class="candidate-sidebar__date"
              id="addDateFrom"
              mode="date"
              @update:modelValue="onCreatedAtFrom"
              @dateInput="onDateFrom"
              v-model="selectedFilters.created_at_from"
            />
          </div>
          <div class="candidate-sidebar__field-row">
            <div class="candidate-sidebar__date-label">До</div>
            <VDate
              class="candidate-sidebar__date"
              :options="arrayTypedVacancies"
              @update:modelValue="onCreatedAtTo"
              @dateInput="onDateTo"
              v-model="selectedFilters.created_at_to"
            />
          </div>
        </div>
        <div class="candidate-sidebar__field-wrapper">
          <div class="candidate-sidebar__field-label">Источник</div>
          <VMultiSelect
            class="candidate-sidebar__select"
            placeholder="Выберите источник"
            label="Источник"
            :id="5"
            :options="filterOptions?.source"
            :isWithReset="true"
            @update:modelValue="onVacancyTypeSelect"
            v-model="selectedFilters.source"
          />
        </div>
        <div class="candidate-sidebar__field-wrapper">
          <div class="candidate-sidebar__field-label">Метки</div>
          <VMultiSelect
            class="candidate-sidebar__select"
            placeholder="Выберите метку"
            label="Метки"
            :options="filterOptions?.labels"
            :isWithReset="true"
            @update:modelValue="onVacancyTypeSelect"
            v-model="selectedFilters.labels"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, reactive, ref, watch } from "vue";
import VMultiSelect from "@/components/UI/VMultiSelect";
import { debounce } from "@/services/helpers";
import common from "@/api/common";
const emit = defineEmits([
  "update:modelValue",
  "filterSelected",
  "vacancyTypeSelect",
  "vacancyStepSelect",
  "vacancyApprovalFilterSelect",
]);
const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  title: {
    type: String,
    required: false,
  },
  subtitle: {
    type: String,
    required: false,
  },
  isHeadDetached: {
    type: Boolean,
    default: true,
  },
  role: {
    type: String,
  },
  modelValue: {
    type: Object,
    required: true,
  },
  filterOptions: {
    type: Object,
  },
});

const selectedFilters = reactive({
  vacancy_id: null,
  age_from: null,
  age_to: null,
  localities: null,
  specialities: [],
  experience_from: null,
  experience_to: null,
  created_at_from: "",
  created_at_to: "",
  "experience[from]": null,
  "experience[to]": null,
  "created_at[from]": "",
  "created_at[to]": "",
  source: [],
  labels: [],
  status: [],
});

const resultsResidence = ref([]);
const resultsVacancy = ref([]);
const searchResidenceValue = ref("");
const searchVacancyValue = ref("");
const filteredVacancy = computed(() => {
  return resultsVacancy.value.filter((item) =>
    item.name.toLowerCase().includes(searchVacancyValue.value.toLowerCase())
  );
});
const arrayTypedVacancies = computed(() => {
  let formatedArr = Object.entries(
    props?.data?.filter?.vacancies_list || {}
  ).map(([key, val]) => {
    return { id: key, label: val };
  });
  return [{ id: "all", label: "Все" }, ...formatedArr.reverse()];
});

const onSelectResidence = (item) => {
  selectedFilters.city = item?.id;
  selectedFilters.localities = item?.id;
  searchResidenceValue.value = item?.name;
};

const onSelectVacancy = (item) => {
  selectedFilters.vacancy_id = item?.id;
  searchVacancyValue.value = item?.name;
};

const onSearchResidenceBlur = () => {};
const searchResidence = async () => {
  if (!searchResidenceValue.value?.length) {
    selectedFilters.city = null;
    selectedFilters.localities = null;
  }
  const res = await common.searchResidence(searchResidenceValue.value);
  if (res?.data)
    resultsResidence.value = res?.data.map((item) => {
      return {
        ...item,
        name: item?.regionDescription
          ? `${item?.regionDescription} ${item?.name}`
          : `${item?.name}`,
      };
    });
};

const searchVacancy = async () => {
  if (!searchVacancyValue.value?.length) {
    selectedFilters.vacancy_id = null;
  }
};
const debouncedSearchResidence = debounce(() => searchResidence(), 400);
const debouncedSearchVacancy = debounce(() => searchVacancy(), 400);
const selectStepFilter = (stepId) => {
  selectedFilters.stepFilterId = stepId;
  selectedFilters.isAccepted = null;
  selectedFilters.isCancelled = null;
};

const onStatusSelect = (val) => {
  const findedIndex = selectedFilters.status.indexOf(val);
  if (findedIndex >= 0) {
    selectedFilters.status.filter((_, index) => index != findedIndex);
  } else {
    selectedFilters.status.push(val);
  }
};

const onExperienceFrom = (val) => {
  selectedFilters["experience[from]"] = val;
};

const onExperienceTo = (val) => {
  selectedFilters["experience[to]"] = val;
};

const onCreatedAtFrom = (val) => {
  selectedFilters["created_at[from]"] = val.toISOString().split("T")[0];
};

const onDateFrom = (val) => {
  if (!val) {
    selectedFilters["created_at[from]"] = "";
  }
};

const onCreatedAtTo = (val) => {
  selectedFilters["created_at[to]"] = val.toISOString().split("T")[0];
};

const onDateTo = (val) => {
  if (!val) {
    selectedFilters["created_at[to]"] = "";
  }
};

const selectVacancyStepFilter = (vacancyStepFilter, type) => {
  if (type) {
    selectedFilters.approvalId = vacancyStepFilter?.filter_approval_id;
    selectedFilters.stepFilterId = undefined;
    selectedFilters.isAccepted = null;
    selectedFilters.isCancelled = null;
  } else {
    selectedFilters.stepFilterId = vacancyStepFilter?.filter_step_id;
    selectedFilters.approvalId = null;
    selectedFilters.isAccepted = null;
    selectedFilters.isCancelled = null;
  }
};

const selectApprovalFilter = (type) => {
  if (type === "is_accepted") {
    selectedFilters.isAccepted = 1;
    selectedFilters.isCancelled = null;
    selectedFilters.approvalId = null;
    selectedFilters.stepFilterId = null;
  }
  if (type === "is_cancelled") {
    selectedFilters.isCancelled = 1;
    selectedFilters.isAccepted = 0;
    selectedFilters.approvalId = null;
    selectedFilters.stepFilterId = null;
  }
};

watch(
  selectedFilters,
  (newVal, OldVal) => {
    emit("update:modelValue", selectedFilters);
  },
  { deep: true, immediate: false }
);

onMounted(async () => {
  const res = await common.getVacanciesReferences(searchVacancyValue.value);
  if (res?.data)
    resultsVacancy.value = res?.data.map((item) => ({
      id: item?.id,
      name: item?.title,
    }));
});
</script>

<style scoped lang="scss">
.candidate-sidebar {
  flex-grow: 0;
  border-radius: 16px;

  :deep(.v-select__options) {
    max-height: 312px;
    overflow-y: auto;
    scrollbar-width: thin;
  }

  &__wrapper {
    border-radius: 16px 16px 0 0;

    &_bordered {
      border-radius: 16px;
    }
  }

  &__head {
    padding: 16px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.04);
  }

  &__main {
    padding: 16px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.04);
  }

  &__footer {
    padding: 12px 12px 16px 12px;
    border-top: solid 1px $light-gray-1;
    background-color: #fff;
    box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.04);
    border-radius: 0 0 16px 16px;
  }

  &__title {
    margin-bottom: 12px;
    font-size: 18px;
    font-weight: 600;
  }
  &__candidate-title {
    margin-bottom: 16px;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.78px;
  }

  &__subtitle {
    margin-bottom: 16px;
    font-size: 18px;
    font-weight: 600;
  }

  &__field-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 12px;
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__field-row {
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    flex: 1 1 100%;
    gap: 12px;
  }

  &__field-label {
    color: var($dark);
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
  }

  &__select {
    width: 100%;
    height: 40px;

    :deep(.v-select__header) {
      height: 40px;
    }

    :deep(.v-select__header-value) {
      top: 12px;
      padding-right: 20px;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow-x: hidden;
      font-weight: 500;
    }
    :deep(.v-select__header_selected) {
      .v-select__header-palceholder {
        top: 2px;
        display: none;
      }
    }
    :deep(.v-select__header-palceholder) {
      color: $gray;
    }
    :deep(.v-input__placeholder) {
      color: $gray;
    }
  }

  &__date {
    flex: 0 0 auto;
    :deep(.app-date__date-inp-wrapper) {
      height: 40px;
      width: 193px;
    }
    :deep(.app-date__date-inp) {
      &::placeholder {
        color: $gray;
      }
    }
  }

  &__date-label {
    margin-top: 12px;
    min-width: 19px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    color: #8f9cac;
  }

  &__btns {
    width: 100%;
  }

  &__btn {
    width: 100%;
    height: 40px;
    padding: 0 12px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: 0.3s;
    cursor: pointer;

    &_active {
      background-color: #f0f2f4;
    }

    &_with-fio {
      width: 100%;
      height: 40px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      transition: 0.3s;
      cursor: pointer;
    }
  }

  &__btn-row {
    display: flex;
  }

  &__btn-cell {
    display: flex;
    flex-direction: column;
    margin-right: auto;
  }

  &__btn-title {
    font-size: 14px;
    font-weight: 400;
  }

  &__btn-label {
    font-size: 12px;
    font-weight: 400;
    line-height: 15.86px;
    opacity: 0.7;
  }

  &__btn-fio {
    font-size: 14px;
    font-weight: 400;
    line-height: 18.51px;
  }

  &__btn-num {
    color: $gray;
    font-size: 14px;
    font-weight: 500;
    align-content: center;
  }
}
.head {
  &_detached {
    margin-bottom: 24px;
  }
}
</style>
