<template>
  <DatePicker
    :id="id"
    class="app-date"
    mode="date"
    :masks="masks"
    :popover="popover"
    v-model="inpuValue"
    @update:modelValue="setTime"
  >
    <template v-slot="{ togglePopover, inputValue, inputEvents }">
      <div class="app-date__date">
        <div
          class="app-date__date-inp-wrapper"
          :class="{
            'app-date__date-inp-wrapper_disabled': isDisabled,
            'app-date__date-inp-wrapper_error': isError,
          }"
        >
          <input
            class="app-date__date-inp"
            :placeholder="placeholder"
            v-mask="'00.00.0000'"
            :value="inputValue"
            v-on="inputEvents"
            @input="dateInput"
          />

          <div
            @click="() => togglePopover()"
            class="app-date__date-inp-icon app-date__date-inp-icon_active"
          >
            <img src="@/assets/images/icons/calendar.svg" alt="calendar" />
          </div>
        </div>

        <p class="app-date__date-inp-error" v-if="isError">
          Заполните обязательное поле
        </p>
      </div>
    </template>
  </DatePicker>
</template>
<script setup>
import { DatePicker } from "v-calendar";
import "v-calendar/dist/style.css";
import { ref } from "vue";
const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  mode: {
    type: String,
    default: "date",
    required: true,
  },
  data: {
    type: Object,
    required: true,
  },
  label: {
    type: String,
    required: false,
  },
  placeholder: {
    type: String,
    required: false,
  },
  isError: {
    type: Boolean,
    required: false,
  },
  isDisabled: {
    type: Boolean,
    required: false,
  },
  modelValue: {
    type: Object,
    required: true,
  },
});
const emit = defineEmits(["update:modelValue", "dateInput"]);

const inpuValue = ref();
const masks = {
  input: "DD.MM.YYYY",
};
const popover = ref({
  visibility: "",
  placement: "bottom",
});

function setTime(e) {
  emit("update:modelValue", e);
}

function dateInput(e) {
  emit("dateInput", e?.target?.value);
}
</script>
<style lang="scss">
.app-date {
  &__date-inp-wrapper {
    width: 100%;
    height: 48px;
    padding: 0 16px;
    border-radius: 8px;
    background-color: $light;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_disabled {
      position: relative;
      opacity: 0.3;
      user-select: none;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 4;
      }
    }

    &_error {
      border: 1px solid #dd4646;
    }
  }

  &__date-inp {
    width: 100%;
    height: 100%;
    font-size: 14px;
    background-color: transparent;

    &::placeholder {
      color: $dark-blue;
    }

    &_disabled {
      &::placeholder {
        opacity: 0.3;
      }
    }
  }

  &__date-inp-wrapper_disabled &__date-inp {
    opacity: 0.3;
    cursor: no-drop;
    user-select: none;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 4;
    }
  }

  &__date-inp-error {
    margin-top: 4px;
    font-size: 14px;
    color: #dd4646;
  }
}
</style>
