<template>
  <header class="header-main">
    <div class="header-main__wrapper main-container">
      <div class="header-main__menu">
        <router-link to="/">
        <div>
          <svg
            width="204"
            height="24"
            viewBox="0 0 204 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.271666 7.67988C0.139239 7.30509 0.417262 6.91199 0.814761 6.91199H6.3078C6.55446 6.91199 6.77374 7.06904 6.85314 7.30257L11.3575 20.5506C11.4845 20.9242 11.2067 21.312 10.8121 21.312H5.49572C5.25158 21.312 5.03396 21.1581 4.95263 20.9279L0.271666 7.67988Z"
              fill="url(#paint0_linear_15897_4523)"
            />
            <path
              d="M15.7287 14.3306C15.8069 13.9715 15.5334 13.632 15.1659 13.632H10.261C9.98801 13.632 9.75253 13.8236 9.697 14.0908L8.46025 20.0427C8.33461 20.6473 7.88785 21.1345 7.29634 21.312H13.7443C14.0152 21.312 14.2494 21.1232 14.3071 20.8586L15.7287 14.3306Z"
              fill="white"
            />
            <path
              d="M23.6735 16.3201C23.8059 16.6949 23.5279 17.088 23.1304 17.088H17.6374C17.3907 17.088 17.1714 16.9309 17.092 16.6974L12.5877 3.4494C12.4607 3.0758 12.7385 2.68799 13.1331 2.68799H18.4495C18.6936 2.68799 18.9112 2.8419 18.9926 3.07209L23.6735 16.3201Z"
              fill="url(#paint1_linear_15897_4523)"
            />
            <path
              d="M8.21647 9.66942C8.13827 10.0285 8.41179 10.368 8.77928 10.368H13.6842C13.9572 10.368 14.1927 10.1764 14.2482 9.90917L15.4849 3.9573C15.6106 3.35266 16.0573 2.86544 16.6488 2.68799H10.2009C9.93002 2.68799 9.69574 2.87674 9.6381 3.14142L8.21647 9.66942Z"
              fill="white"
            />
            <g clip-path="url(#clip0_15897_4523)">
              <path
                d="M33 4.875H50.0291V19.125H46.4311V7.54088H36.598V19.125H33V4.875Z"
                fill="white"
              />
              <path
                d="M53.3869 19.125V4.875H68.0802V7.54088H56.9849V10.5712H67.289V13.0261H56.9849V16.4591H68.2309V19.125H53.3869Z"
                fill="white"
              />
              <path
                d="M71.2853 19.125V4.875H80.9301C85.2628 4.875 87.6175 6.62029 87.6175 9.70811C87.6175 12.7959 85.3193 14.8097 80.949 14.8097H74.8833V19.125H71.2853ZM74.8833 12.1438H81.062C83.1153 12.1438 83.9818 11.1465 83.9818 9.76565C83.9818 8.55737 83.1153 7.54088 81.0432 7.54088H74.8833V12.1438Z"
                fill="white"
              />
              <path
                d="M90.3795 19.125V4.875H105.073V7.54088H93.9775V10.5712H104.282V13.0261H93.9775V16.4591H105.223V19.125H90.3795Z"
                fill="white"
              />
              <path
                d="M126.667 19.0922H130.648L132.158 15.9791H140.65L142.14 19.0922H146.16L138.781 4.9872H133.988L126.667 19.0922ZM133.309 13.5677L136.253 7.49277L136.385 7.17047H136.423L136.574 7.49277L139.499 13.5677H133.309Z"
                fill="white"
              />
              <path
                d="M181.017 19.0263H185.001L186.512 15.9111H195.008L196.5 19.0263H200.522L193.139 4.91302H188.344L181.017 19.0263ZM187.664 13.499L190.609 7.42065L190.741 7.09766H190.779L190.93 7.42065L193.857 13.499H187.664Z"
                fill="white"
              />
              <path
                d="M172.006 19.077H175.613V7.60398H182.202V4.96371H165.416V7.60398H172.006V19.077Z"
                fill="white"
              />
              <path
                d="M148.193 19.0215C148.034 19.0215 147.909 18.9926 147.819 18.9335C147.726 18.873 147.679 18.7899 147.679 18.6834C147.679 18.5514 147.745 18.4188 147.876 18.2848L153.772 11.8772V11.737L148.253 5.80779C148.134 5.66141 148.075 5.53497 148.075 5.42845C148.075 5.32262 148.12 5.24015 148.212 5.18105C148.305 5.12058 148.431 5.09034 148.589 5.09034H151.141C151.431 5.09034 151.668 5.19067 151.854 5.38996L155.89 9.80735L159.945 5.38996C160.128 5.19067 160.359 5.09034 160.636 5.09034H163.207C163.366 5.09034 163.487 5.12058 163.573 5.18105C163.659 5.24015 163.702 5.32262 163.702 5.42845C163.702 5.53497 163.642 5.66141 163.524 5.80779L158.027 11.737V11.8772L163.92 18.2848C164.038 18.4188 164.098 18.5452 164.098 18.6641C164.098 18.7706 164.051 18.8565 163.958 18.9225C163.867 18.9885 163.743 19.0215 163.584 19.0215H161.032C160.768 19.0215 160.538 18.9156 160.341 18.7026L155.89 13.8866L151.458 18.7026C151.352 18.8222 151.243 18.9053 151.13 18.9528C151.019 18.9988 150.89 19.0215 150.745 19.0215H148.193Z"
                fill="white"
              />
              <path
                d="M108.5 19.0922H119.463C123.162 19.0922 125.445 17.9913 125.445 15.2954C125.445 13.3588 123.936 11.9541 121.237 11.6882V11.6696C123.653 11.4037 124.747 10.0368 124.747 8.34697C124.747 6.01183 122.822 4.9872 119.482 4.9872H108.5V19.0922ZM112.104 16.4348V13.0173H118.501C120.614 13.0173 121.709 13.5492 121.709 14.7641C121.709 16.0547 120.671 16.4348 118.935 16.4348H112.104ZM112.104 10.7392V7.62609H119.067C120.916 7.62609 121.332 8.34697 121.332 9.16338C121.332 10.1887 120.52 10.7392 118.614 10.7392H112.104Z"
                fill="white"
              />
            </g>
            <defs>
              <linearGradient
                id="paint0_linear_15897_4523"
                x1="7.60859"
                y1="2.68799"
                x2="12.1164"
                y2="22.6175"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="white" />
                <stop offset="1" stop-color="#BBC4CD" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_15897_4523"
                x1="7.60859"
                y1="2.68799"
                x2="12.1164"
                y2="22.6175"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="white" />
                <stop offset="1" stop-color="#BBC4CD" />
              </linearGradient>
              <clipPath id="clip0_15897_4523">
                <rect
                  width="167.625"
                  height="14.25"
                  fill="white"
                  transform="translate(33 4.875)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
      </router-link>
        <nav v-if="role === 'SUPER_ADMIN'" class="header-main__nav">
          <router-link to="/companies" class="header-main__nav-item">
            <div class="header-main__nav-item-icon">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 8L6.01 7M4 4H20C21.1046 4 22 4.7835 22 5.75V9.25C22 10.2165 21.1046 11 20 11H4C2.89543 11 2 10.2165 2 9.25V5.75C2 4.7835 2.89543 4 4 4Z"
                  stroke="#F6F7F8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6 18L6.01 17M4 14H20C21.1046 14 22 14.7835 22 15.75V19.25C22 20.2165 21.1046 21 20 21H4C2.89543 21 2 20.2165 2 19.25V15.75C2 14.7835 2.89543 14 4 14Z"
                  stroke="#F6F7F8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <p class="header-main__nav-name">Компании</p>
          </router-link>
          <router-link to="/super-admins" class="header-main__nav-item">
            <div class="header-main__nav-item-icon">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.0909 19.3637V17.7273C16.0909 16.8593 15.7461 16.0269 15.1323 15.4132C14.5186 14.7994 13.6862 14.4546 12.8182 14.4546H6.27273C5.40475 14.4546 4.57232 14.7994 3.95856 15.4132C3.3448 16.0269 3 16.8593 3 17.7273V19.3637"
                  stroke="#F6F7F8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M9.54519 11.1818C11.3527 11.1818 12.8179 9.71656 12.8179 7.90908C12.8179 6.1016 11.3527 4.63635 9.54519 4.63635C7.73771 4.63635 6.27246 6.1016 6.27246 7.90908C6.27246 9.71656 7.73771 11.1818 9.54519 11.1818Z"
                  stroke="#F6F7F8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M21.0004 19.3635V17.7272C20.9999 17.002 20.7586 16.2976 20.3143 15.7245C19.87 15.1514 19.248 14.7421 18.5459 14.5608"
                  stroke="#F6F7F8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M15.2725 4.74268C15.9764 4.92292 16.6004 5.33234 17.046 5.90638C17.4916 6.48043 17.7334 7.18645 17.7334 7.91313C17.7334 8.63982 17.4916 9.34583 17.046 9.91988C16.6004 10.4939 15.9764 10.9033 15.2725 11.0836"
                  stroke="#F6F7F8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <p class="header-main__nav-name">Администраторы</p>
          </router-link>
          <router-link to="/specialties" class="header-main__nav-item">
            <div class="header-main__nav-item-icon">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 6H21"
                  stroke="#F6F7F8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8 12H21"
                  stroke="#F6F7F8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8 18H21"
                  stroke="#F6F7F8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3 6H3.01"
                  stroke="#F6F7F8"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3 12H3.01"
                  stroke="#F6F7F8"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3 18H3.01"
                  stroke="#F6F7F8"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <p class="header-main__nav-name">Специальности</p>
          </router-link>
        </nav>
        <nav v-if="role === 'MAIN_HR'" class="header-main__nav">
          <router-link to="/employees" class="header-main__nav-item">
            <div class="header-main__nav-item-icon">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.4091 16.1364V14.7727C13.4091 14.0494 13.1218 13.3557 12.6103 12.8443C12.0988 12.3328 11.4051 12.0455 10.6818 12.0455H5.22727C4.50396 12.0455 3.81026 12.3328 3.2988 12.8443C2.78734 13.3557 2.5 14.0494 2.5 14.7727V16.1364"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.95481 9.31819C9.46104 9.31819 10.6821 8.09715 10.6821 6.59092C10.6821 5.08469 9.46104 3.86365 7.95481 3.86365C6.44858 3.86365 5.22754 5.08469 5.22754 6.59092C5.22754 8.09715 6.44858 9.31819 7.95481 9.31819Z"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M17.4996 16.1362V14.7725C17.4991 14.1683 17.298 13.5813 16.9278 13.1037C16.5575 12.6261 16.0392 12.285 15.4541 12.1339"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12.7275 3.95227C13.3142 4.10248 13.8342 4.44366 14.2055 4.92203C14.5768 5.4004 14.7783 5.98875 14.7783 6.59432C14.7783 7.19989 14.5768 7.78823 14.2055 8.2666C13.8342 8.74497 13.3142 9.08616 12.7275 9.23636"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <p class="header-main__nav-name">Сотрудники</p>
          </router-link>
          <router-link to="/settings" class="header-main__nav-item">
            <div class="header-main__nav-item-icon">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.93933 16.9697V11.6667"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3.93933 8.6364V3.33337"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10 16.9697V10.1516"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10 7.12125V3.33337"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.0605 16.9697V13.1819"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.0605 10.1516V3.33337"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M1.66663 11.6667H6.21208"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.72717 7.12125H12.2726"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M13.7878 13.1819H18.3333"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <p class="header-main__nav-name">Настройки</p>
          </router-link>
        </nav>
        <nav v-if="role === 'HR'" class="header-main__nav">
          <router-link to="/vacancies" class="header-main__nav-item">
            <div class="header-main__nav-item-icon">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16 5.1189H4C3.17157 5.1189 2.5 5.80417 2.5 6.64951V14.3026C2.5 15.1479 3.17157 15.8332 4 15.8332H16C16.8284 15.8332 17.5 15.1479 17.5 14.3026V6.64951C17.5 5.80417 16.8284 5.1189 16 5.1189Z"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12.8125 15.8333V4.72214C12.8125 4.35378 12.6643 4.00052 12.4006 3.74005C12.1369 3.47958 11.7792 3.33325 11.4062 3.33325H8.59375C8.22079 3.33325 7.8631 3.47958 7.59938 3.74005C7.33566 4.00052 7.1875 4.35378 7.1875 4.72214V15.8333"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <p class="header-main__nav-name">Вакансии</p>
          </router-link>
          <router-link to="/responses" class="header-main__nav-item">
            <div class="header-main__nav-item-icon">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.5 13.125H7.10598C7.62374 13.125 8.04348 13.5447 8.04348 14.0625V14.0625C8.04348 14.5803 8.46321 15 8.98098 15H10H11.019C11.5368 15 11.9565 14.5803 11.9565 14.0625V14.0625C11.9565 13.5447 12.3763 13.125 12.894 13.125H17.5"
                  stroke="white"
                />
                <rect
                  x="2.5"
                  y="2.5"
                  width="15"
                  height="15"
                  rx="4"
                  stroke="white"
                />
              </svg>
            </div>
            <p class="header-main__nav-name">Задачи</p>
          </router-link>
          <router-link to="/candidates" class="header-main__nav-item">
            <div class="header-main__nav-item-icon">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.9999 16.7501V15.25C15.9999 14.4544 15.6838 13.6913 15.1212 13.1287C14.5586 12.5661 13.7955 12.25 12.9999 12.25H6.9998C6.20414 12.25 5.44106 12.5661 4.87845 13.1287C4.31583 13.6913 3.99976 14.4544 3.99976 15.25V16.7501" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M10 9.25008C11.6569 9.25008 13.0001 7.90692 13.0001 6.25004C13.0001 4.59316 11.6569 3.25 10 3.25C8.34316 3.25 7 4.59316 7 6.25004C7 7.90692 8.34316 9.25008 10 9.25008Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <p class="header-main__nav-name">База кандидатов</p>
          </router-link>
          <router-link to="/templates" class="header-main__nav-item">
            <div class="header-main__nav-item-icon">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="2.5"
                  y="2.5"
                  width="15"
                  height="8"
                  rx="1.5"
                  stroke="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M16 13.5C16 13.7761 15.7761 14 15.5 14H2.5C2.22386 14 2 13.7761 2 13.5C2 13.2239 2.22386 13 2.5 13H15.5C15.7761 13 16 13.2239 16 13.5Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12 16.5C12 16.7761 11.7761 17 11.5 17H2.5C2.22386 17 2 16.7761 2 16.5C2 16.2239 2.22386 16 2.5 16H11.5C11.7761 16 12 16.2239 12 16.5Z"
                  fill="white"
                />
              </svg>
            </div>
            <p class="header-main__nav-name">Шаблоны</p>
          </router-link>
        </nav>
        <nav v-if="role === 'APPROVING_PERSON'" class="header-main__nav">
          <router-link to="/responses" class="header-main__nav-item">
            <div class="header-main__nav-item-icon">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.5 13.125H7.10598C7.62374 13.125 8.04348 13.5447 8.04348 14.0625V14.0625C8.04348 14.5803 8.46321 15 8.98098 15H10H11.019C11.5368 15 11.9565 14.5803 11.9565 14.0625V14.0625C11.9565 13.5447 12.3763 13.125 12.894 13.125H17.5"
                  stroke="white"
                />
                <rect
                  x="2.5"
                  y="2.5"
                  width="15"
                  height="15"
                  rx="4"
                  stroke="white"
                />
              </svg>
            </div>
            <p class="header-main__nav-name">Задачи</p>
          </router-link>
        </nav>
        <nav v-if="role === 'SECURITY_SERVICE'" class="header-main__nav">
          <router-link to="/sb-tasks" class="header-main__nav-item">
            <div class="header-main__nav-item-icon">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.3334 3.33301H15C15.4421 3.33301 15.866 3.5086 16.1786 3.82116C16.4911 4.13372 16.6667 4.55765 16.6667 4.99967V16.6663C16.6667 17.1084 16.4911 17.5323 16.1786 17.8449C15.866 18.1574 15.4421 18.333 15 18.333H5.00004C4.55801 18.333 4.13409 18.1574 3.82153 17.8449C3.50897 17.5323 3.33337 17.1084 3.33337 16.6663V4.99967C3.33337 4.55765 3.50897 4.13372 3.82153 3.82116C4.13409 3.5086 4.55801 3.33301 5.00004 3.33301H6.66671"
                  stroke="#1E3959"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12.5001 1.6665H7.50008C7.03984 1.6665 6.66675 2.0396 6.66675 2.49984V4.1665C6.66675 4.62674 7.03984 4.99984 7.50008 4.99984H12.5001C12.9603 4.99984 13.3334 4.62674 13.3334 4.1665V2.49984C13.3334 2.0396 12.9603 1.6665 12.5001 1.6665Z"
                  stroke="#1E3959"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <p class="header-main__nav-name">Задачи</p>
          </router-link>
        </nav>
        <div
          @click="showPersonalDataWindow = true"
          class="header-main__profile-btn"
        >
        
          <div class="header-main__profile-btn-col">
            <div class="header-main__profile-btn-name">
              {{ profileInfo?.full_name?.value }}
            </div>
            <p
              v-if="role === 'SUPER_ADMIN'"
              class="header-main__profile-btn-type"
            >
              Администратор
            </p>
            <p v-if="role === 'HR'" class="header-main__profile-btn-type">
              HR-менеджер
            </p>
            <p v-if="role === 'MAIN_HR'" class="header-main__profile-btn-type">
              Руководитель подбора
            </p>
            <p
              v-if="role === 'SECURITY_SERVICE'"
              class="header-main__profile-btn-type"
            >
              СБ
            </p>
            <p
              v-if="role === 'APPROVING_PERSON'"
              class="header-main__profile-btn-type"
            >
              Согласовант
            </p>
          </div>
        </div>
      </div>
    </div>

    <ModalWindow v-if="showPersonalDataWindow">
      <PersonalData
        :profileInfo="profileInfo"
        @close="showPersonalDataWindow = false"
        @openLogout="openLogoutModal"
        @openChangePasswordModal="openChangePasswordModal"
      />
    </ModalWindow>

    <ModalWindow v-if="showLogoutWindow">
      <LogOut @close="showLogoutWindow = false" @logout="logout" />
    </ModalWindow>

    <ModalWindow v-if="showChangePassword">
      <ChangePassword
        :errorPasswordText="errorPasswordText"
        @close="(showChangePassword = false), (errorPasswordText = '')"
        @change="postChangePassword"
      />
    </ModalWindow>
  </header>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import apiProfile from "@/api/profile";
import ModalWindow from "@/components/ModalWindow/index.vue";
import PersonalData from "@/components/ModalWindow/ModalBodyes/PersonalData";
import LogOut from "@/components/ModalWindow/ModalBodyes/LogOut";
import ChangePassword from "@/components/ModalWindow/ModalBodyes/ChangePassword";

const store = useStore();
const router = useRouter();

const role = store.getters["auth/role"];

const showPersonalDataWindow = ref(false);
const showLogoutWindow = ref(false);
const showChangePassword = ref(false);

const logout = async () => {
  await apiProfile.getLogout();
  await store.dispatch("auth/logout");
  setTimeout(() => {
    showLogoutWindow.value = false;
    router.push("/authorization");
  }, 400);
};

const openLogoutModal = () => {
  showPersonalDataWindow.value = false;
  showLogoutWindow.value = true;
};

const openChangePasswordModal = () => {
  showPersonalDataWindow.value = false;
  showChangePassword.value = true;
};

const profileInfo = ref({});
const getProfile = async () => {
  const response = await apiProfile.getProfile();
  profileInfo.value = response;
  await store.dispatch("auth/setProfile", response);
};
const errorPasswordText = ref("");
const postChangePassword = async (body) => {
  errorPasswordText.value = "";
  try {
    const response = await apiProfile.postChangePassword(body);
    await store.dispatch("auth/logout");
    setTimeout(() => {
      showChangePassword.value = false;
      router.push("/authorization");
    }, 400);
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.errors?.current_password.length) {
      errorPasswordText.value = error.response.data.errors.current_password[0];
    } else {
      await store.dispatch("auth/logout");
      setTimeout(() => {
        showChangePassword.value = false;
        router.push("/authorization");
      }, 400);
    }
  } finally {
  }
};

onMounted(() => {
  getProfile();
});
</script>

<style scoped lang="scss">
.header-main {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  background: #0b5794;
  user-select: none;
  z-index: 99;

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__logo {
    width: 187px;
  }

  &__menu {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
  }

  &__nav {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    justify-content: flex-end;
    margin-right: 64px;
  }

  &__nav-item {
    padding: 8px 12px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    text-decoration: none;

    &:not(:first-child) {
      margin-left: 12px;
    }
  }

  &__nav-item-icon {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.5;
  }

  &__nav-name {
    margin-left: 8px;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    opacity: 0.5;
  }

  .router-link-active {
    position: relative;
    ::after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1.5px;
      background-color: #fff;
      content: "";
    }
    & > .header-main__nav-item-icon {
      opacity: 1;
      path,
      rect {
        stroke: #fff;
        color: #fff;
      }
    }

    & > .header-main__nav-name {
      opacity: 1;
    }
  }

  &__profile-btn {
    max-width: 160px;
    margin-left: auto;
    cursor: pointer;
  }

  &__profile-btn-name {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__profile-btn-type {
    width: 100px;
    color: #fff;
    margin-top: 4px;
    font-size: 12px;
    font-weight: 400;
    opacity: 0.5;
    line-height: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__profile-chat-btn {
    width: 133px;
    margin-left: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    cursor: pointer;

    p {
      margin-left: 8px;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      span {
        margin-left: 4px;
      }
    }

    img {
      margin-left: 8px;
    }
  }
}
</style>
