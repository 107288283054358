<template>
  <div class="candidate-attention">
    <div class="candidate-attention__close" @click.prevent="$emit('close')">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15 5L5 15"
          stroke="#333333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5 5L15 15"
          stroke="#333333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <img
      class="candidate-attention__icon"
      src="@/assets/images/icons/alert-circle.png"
    />
    <h2 class="candidate-attention__title">Найден дубликат</h2>
    <p class="candidate-attention__description">
      Добавляемый кандидат уже есть&nbsp;в&nbsp;системе
    </p>
    <VButton
      label="Заменить"
      bg="#0D5FCB"
      color="#fff"
      class="candidate-attention__button"
      @click="replace"
    />
    <VButton
      label="Отменить"
      bg="#E4E7EE"
      bgHover="#BBC4CD"
      color="#1E3959"
      colorHover="#1E3959"
      class="candidate-attention__button"
      @click="$router.push('/candidates')"
    />
  </div>
</template>
<script setup>
const emit = defineEmits(["close", "replaceSuccess"]);

const replace = () => {
  new Promise(resolve => resolve()).then(() => emit('replaceSuccess'))
}
</script>
<style lang="scss" scoped>
.candidate-attention {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 40px 24px 24px;

  &__close {
        position: absolute;
        top: 16px;
        right: 16px;
        cursor: pointer;
  }

  &__icon {
    height: 56px;
    width: 56px;
    flex: 0 0 auto;
    align-self: center;
    margin-bottom: 16px;
  }

  &__title {
    margin-bottom: 8px;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    line-height: 24.2px;
  }

  &__description {
    margin-bottom: 24px;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.8px;
  }
  &__button {
    &:not(:last-child){
    margin-bottom: 12px;
    }
  }
}
</style>
