<template>
  <div class="candidate-dublicate">
    <div class="candidate-dublicate__close" @click.prevent="$emit('close')">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15 5L5 15"
          stroke="#333333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5 5L15 15"
          stroke="#333333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <h2 class="candidate-dublicate__title">Дубликат</h2>
    <div class="candidate-dublicate__body">
      <div class="candidate-dublicate__form">
        <div class="candidate-dublicate__form-header">
          <h2 class="candidate-dublicate__form-title">Данные кандидата</h2>
          <VSelect
            class="candidate-dublicate__form-header-select candidate-dublicate__input_disabled"
            placeholder="Статус"
            :options="statusOptions"
            v-model="form.status"
          />
        </div>

        <section class="candidate-dublicate__form-section">
          <h3 class="candidate-dublicate__form-section-title">Личные данные</h3>
          <div class="candidate-dublicate__form-row">
            <VCustomInput
              class="candidate-dublicate__select candidate-dublicate__input_disabled"
              placeholder="ФИО*"
              v-model="form.full_name"
            />
            <VSegmentedControl
              :items="genderOptions"
              v-model="form.gender"
              class="candidate-dublicate__switch candidate-dublicate__input_disabled"
              id="gender"
              @update:modelValue="(val) => onGenderUpdate(val)"
            />
          </div>
          <div class="candidate-dublicate__form-row">
            <VCustomInput
              class="candidate-dublicate__select candidate-dublicate__input_disabled"
              placeholder="Дата рождения*"
              v-model="form.birthdate"
              v-mask="'00.00.0000'"
            />
            <VSearchSelect
              class="candidate-dublicate__select candidate-dublicate__input_disabled"
              placeholder="Город проживания*"
              v-model="form.residence.name"
            />
          </div>
        </section>
        <section class="candidate-dublicate__form-section">
          <h3 class="candidate-dublicate__form-section-title">
            Контактные данные
          </h3>
          <div class="candidate-dublicate__form-row">
            <VCustomInput
              class="candidate-dublicate__select candidate-dublicate__input_disabled"
              placeholder="Телефон*"
              v-model="form.phone"
              v-mask="'+0(000)-000-00-00'"
              type="text"
              name="mask"
            />
            <VCustomInput
              class="candidate-dublicate__select candidate-dublicate__input_disabled"
              placeholder="Почта"
              v-model="form.email"
            />
          </div>
        </section>
        <section class="candidate-dublicate__form-section">
          <h3 class="candidate-dublicate__form-section-title">
            Дополнительные данные
          </h3>
          <div class="candidate-dublicate__form-row">
            <VCustomInput
              class="candidate-dublicate__select candidate-dublicate__input_disabled"
              placeholder="Опыт работы"
              v-model="form.work_experience"
            />
            <VSelect
              class="candidate-dublicate__select candidate-dublicate__input_disabled"
              placeholder="Источник"
              :options="sourceOptions"
              v-model="form.source"
            />
          </div>
          <div class="candidate-dublicate__form-row_full">
            <VTagsInputSelect
              class="candidate-dublicate__select candidate-dublicate__input_disabled"
              placeholder="Должность"
              :options="specialtyOptions"
              :isWithoutCheckmarks="true"
              v-model="form.specialities"
            />
          </div>
          <div class="candidate-dublicate__form-row_full">
            <VTagsInputSelect
              class="candidate-dublicate__select candidate-dublicate__input_disabled"
              placeholder="Метки"
              :options="labelsOptions"
              :isWithoutCheckmarks="true"
              v-model="form.labels"
            />
          </div>
        </section>
        <section class="candidate-dublicate__form-section">
          <h3 class="candidate-dublicate__form-field-title">
            Комментарий по кандидату
          </h3>
          <p class="candidate-dublicate__form-field-label">
            Не более 2000 символов
          </p>
          <div class="candidate-dublicate__form-textarea">
            <VEditor
              placeholder="Напишите комментарий"
              v-model="form.comment"
              id="description"
              class="basic-information__editor candidate-dublicate__input_disabled"
            />
          </div>
        </section>
      </div>
    </div>
    <div class="candidate-dublicate__buttons">
      <VButton
        label="Удалить дубликат"
        bg="#E4E7EE"
        bgHover="#BBC4CD"
        color="#1E3959"
        colorHover="#1E3959"
        class="candidate-dublicate__button"
        @click="deleteDublicate"
      />
      <VButton
        label="Заменить"
        bg="#0D5FCB"
        color="#fff"
        class="candidate-dublicate__button"
        @click="replace"
      />
    </div>
  </div>
</template>
<script setup>
import { onMounted, reactive, ref } from "vue";
import candidates from "@/api/candidates";
import VTagsInputSelect from "@/components/UI/VTagsInputSelect";
const emit = defineEmits(["close", "replaceSuccess", "deleteSuccess"]);
const props = defineProps({
  data: {
    type: Object,
    required: false,
  },
  options: {
    type: Object,
    required: false,
  },
});

const form = reactive({
  full_name: "",
  gender: "male",
  birthdate: "",
  residence: {
    name: "",
  },
  residence_id: null,
  phone: "",
  email: "",
  specialty: "",
  work_experience: "",
  status: "",
  labels: "",
  source: "",
  comment: `<p></p>`,
});

const genderOptions = [
  {
    id: "male",
    label: "Мужчина",
  },
  {
    id: "female",
    label: "Женщина",
  },
];

const statusOptions = ref([]);
const sourceOptions = ref([]);
const labelsOptions = ref([]);
const specialtyOptions = ref([]);

const replace = () => {
  candidates.replaceFromDublicate(props?.data?.id).then((res) => {
    if (res) {
      emit("replaceSuccess");
    }
  });
};

const deleteDublicate = () => {
  candidates.deleteDublicate(props?.data?.id).then((res) => {
    if (res) {
      emit("deleteSuccess");
    }
  });
};
onMounted(() => {
  for (const key in props?.data) {
    if (key === "residence") {
      form.residence.name = props?.data?.residence?.name
        ? `${
            props?.data?.residence?.regionDescription
              ? props?.data?.residence?.regionDescription + ", "
              : ""
          }${props?.data?.residence?.name}`
        : "";
    } else {
      form[key] = props?.data?.[key];
    }
  }

  candidates.getStatuses().then((res) => {
    statusOptions.value = res?.data?.statuses?.map((option, index) => ({
      id: option,
      label: option,
    }));
  });

  candidates.getSources().then((res) => {
    sourceOptions.value = res?.data?.sources?.map((option, index) => ({
      id: option,
      label: option,
    }));
  });
});
</script>
<style lang="scss" scoped>
.candidate-dublicate {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 13px 0px 21px;

  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }

  &__icon {
    height: 56px;
    width: 56px;
    flex: 0 0 auto;
    align-self: center;
    margin-bottom: 16px;
  }

  &__title {
    padding-bottom: 12px;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.36px;
    text-align: center;
    border-bottom: 1px solid $light-gray-1;
  }

  &__description {
    margin-bottom: 24px;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.8px;
  }
  &__buttons {
    display: flex;
    gap: 24px;
    padding-left: 24px;
    padding-right: 24px;
  }
  &__button {
    width: calc(50% - 12px);
  }

  :deep(.v-filtration) {
    border-radius: 4px;
    background-color: $light;
  }
  :deep(.v-filtration__filter) {
    width: 186px;
    border-radius: 4px;
  }

  &__wrapper {
    padding-top: 14px;
  }

  &__breadcrumbs {
    display: flex;
    align-items: center;
    color: $gray;
    text-decoration: none;
    cursor: pointer;
    user-select: none;

    p {
      margin-left: 4px;
      font-size: 14px;
      font-weight: 400;
    }

    img {
      width: 20px;
    }
  }

  &__header {
    margin-top: 8px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__form {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 24px;
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 16px;
  }

  &__form-header {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #e4e7ee;
  }

  &__form-header-select {
    flex: 0 1 224px;
    :deep(.v-select__header) {
      width: 224px;
      height: 44px;
    }
  }

  &__form-title {
    flex: 1 1 50%;
    font-size: 20px;
    font-weight: 500;
  }
  &__form-section {
    margin-top: 20px;
  }
  &__form-section-title {
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    text-align: left;
  }

  &__form-field-title {
    margin-bottom: 4px;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    text-align: left;
  }

  &__form-field-label {
    margin-bottom: 16px;
    color: $gray;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
  }

  &__form-row {
    display: flex;
    gap: 16px;
    margin-bottom: 16px;
    & div {
      flex: 0 1 calc(50% - 8px);
    }
    &_full {
      margin-bottom: 16px;
    }
  }
  &__form-textarea {
    :deep(.v-editor__editor) {
      height: 240px;
    }
  }

  &__input {
    &_disabled {
      pointer-events: none;
      user-select: none;
      color: $dark-blue;
      :deep(.v-input__input) {
        color: $dark-blue;
      }
      :deep(.v-select__header-arrow) {
        display: none;
      }
      :deep(.v-select__header-reset) {
        display: none;
      }
      :deep(.v-select__header-item-crossmark) {
        display: none;
      }
      :deep(.v-select__header-item) {
        padding-right: 2px;
      }
    }
  }

  &__btns {
    width: 100%;
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
  }

  &__btn {
    &:first-child {
      width: 42.307%;

      &:active {
        background-color: $gray !important;
      }
    }

    &:last-child {
      width: 53.846%;

      &:active {
        background-color: #0a3489 !important;
      }
    }
  }
}
</style>
