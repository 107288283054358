<template>
  <div class="candidates">
    <ModalWindow
      v-if="showModalLoader"
      @close="showModalLoader = false"
      class="candidates__modal_loader"
    >
      <DocumentLoader
        :message="loaderMessage"
        @close="showModalLoader = false"
      />
    </ModalWindow>
    <ModalWindow
      v-if="showImportModal"
      @close="showImportModal = false"
      class="candidates__modal_import"
    >
      <CandidateImport
        :data="importResultData"
        @close="showImportModal = false"
        @saveAll="onSaveAll"
        @saveOnlyNew="onSaveOnlyNew"
      />
    </ModalWindow>
    <MainHeader
      class="candidates__header"
      :title="'База кандидатов'"
      :number="totalItems"
      :isDropMenu="isImportShow"
      btnText="Добавить кандидата"
      @btnClick="onCandidateAdd"
    >
      <template #actions>
        <img
          class="candidates__header-action"
          src="@/assets/images/icons/more-horizontal.svg"
          @click="onActionClick"
        />
      </template>
      <template #dropMenu>
        <div class="candidates__header-drop-menu">
          <label class="candidates__header-drop-item">
            <img src="@/assets/images/icons/uiw_file-excel.svg" />
            <span class="candidates__header-drop-text">Импортировать</span>
            <input
              class="candidates__file-import"
              @change="onImportClick"
              type="file"
              accept=".xls,.xlsx"
            />
          </label>
          <div class="candidates__header-drop-item" @click="addExtension">
            <img src="@/assets/images/icons/hugeicons_chrome.svg" />
            <span class="candidates__header-drop-text"
              >Добавить расширение</span
            >
          </div>
        </div>
      </template>
    </MainHeader>

    <div class="candidates__row">
      <VSegmentedControl
        :items="filteringOptions"
        :modelValue="activeFilter"
        @update:modelValue="onSegmentSelect"
        class="candidates__segmented-control"
        :class="{
          'candidates__segmented-control_full': route.meta.role !== 'HR',
        }"
      />

      <VDefaultInput
        v-model="candidateFilters.search"
        @update:modelValue="onCandidatesSearch"
        placeholder="Введите ключевое слово, контакт, комментарий"
        class="candidates__search"
      >
        <template #iconLeft>
          <img src="@/assets/images/icons/search.svg" alt="search" />
        </template>
        <template #iconRight>
          <div
            class="companies__search-clear"
            v-if="candidateFilters.search"
            @click="clearSearch"
          >
            <img src="@/assets/images/icons/x.svg" alt="search" />
          </div>
        </template>
      </VDefaultInput>
    </div>

    <div class="candidates__content-wrapper">
      <CandidatesSidebar
        class="candidates__sidebar"
        title="Вакансия"
        subtitle="Кандидат"
        :data="candidatesList"
        :filterOptions="filterOptions"
        :role="role"
        v-model="sidebarFilters"
      />
      <div class="candidates__content" v-if="candidatesList">
        <HeaderSort
          class="candidates__header-sort"
          :modelValue="sortOrder"
          :scheme="headerScheme"
          @update:modelValue="onSortOrderUpdate"
        />
        <div class="candidates__cards-wrapper" v-if="candidatesList">
          <div class="candidates__cards">
            <ListCard
              ref="listCardElement"
              class="candidates__card"
              v-for="candidate in candidatesList"
              :key="candidate.id"
              :cardData="candidate"
              :scheme="candidatesListScheme"
              @click="$router.push('/candidates/' + candidate.id)"
              @openChat="openChat(candidate.id)"
            >
              <template #field(full_name)="{ cardData }">
                <div class="card-item__column">
                  <div>{{ cardData?.fieldData }}</div>
                  <div
                    v-if="cardData?.data?.has_duplicate"
                    class="item-name__dublicate"
                  >
                    Найден дубликат
                  </div>
                </div>
              </template>
              <template #field(status)="{ cardData }">
                <div class="card-item__column" v-if="cardData?.fieldData">
                  <StatusTag
                    :status="{
                      label: cardData?.fieldData,
                      name: cardData?.fieldData,
                    }"
                  />
                  <div class="card-item__status-text">
                    {{ cardData?.data?.status_duration }}
                  </div>
                </div>
              </template>
              <template #field(labels)="{ cardData }">
                <div
                  class="card-item__tags"
                  v-if="cardData?.fieldData?.length > 0"
                >
                  {{ cardData?.fieldData?.slice(0, 1)?.join(",") }}
                </div>
                <div v-else></div>
              </template>
              <template #field(specialities)="{ cardData }">
                <div class="card-item__specialities">
                  {{ cardData?.fieldData?.slice(0, 1)?.join(",") }}
                </div>
              </template>
              <template #field(residence)="{ cardData }">
                <div
                  class="card-item__residence"
                  v-if="cardData?.fieldData?.name"
                >
                  {{
                    `${
                      cardData?.fieldData?.regionDescription
                        ? cardData?.fieldData?.regionDescription + ", "
                        : ""
                    }${cardData?.fieldData?.name}`
                  }}
                </div>
              </template>
            </ListCard>
          </div>
        </div>
        <div class="candidates__no-content" v-if="!candidatesList?.length">
          <div class="candidates__no-content-wrapper">
            <p class="candidates__no-content-title">Список кандидатов пуст</p>
            <p class="candidates__no-content-description">
              По выбранному фильтру ничего не найдено
            </p>
          </div>
        </div>

        <div class="candidates__no-content" v-if="!candidatesList">
          <VNoContent
            v-if="!candidateFilters.search"
            :title="textNoContent"
            description="По выбранному фильтру ничего не найдено"
          />
          <VNoContent
            v-if="candidateFilters.search"
            title="Ничего не найдено"
            description="Проверьте пожалуйста, правильно ли вы написали название"
          />
        </div>
        <div
          class="candidates__pagination"
          v-if="candidatesList?.pages_count > 1 && showPagination"
        >
          <VButton
            class="candidates__pagination-btn"
            label="Загрузить еще"
            bg="#0D5FCB1A"
            color="#0D5FCB"
            bgHover="#0D5FCB4D"
            colorHover="#0D5FCB"
            @click="loadMore()"
            v-if="page < candidatesList.pages_count && showPagination"
          />
          <VPaginate
            :pageCount="candidatesList.pages_count"
            :modelValue="page"
            @update:modelValue="pagination"
            class="candidates__pagination-pag"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted, nextTick, onUnmounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import candidates from "@/api/candidates";
import MainHeader from "@/components/MainHeader";
import VPaginate from "@/components/UI/VPaginate";
import HeaderSort from "@/components/Common/HeaderSort";
import ListCard from "@/components/Common/ListCard";
import StatusTag from "@/components/Common/StatusTag";
import CandidateImport from "@/components/ModalWindow/ModalBodyes/CandidateImport";
import CandidatesSidebar from "@/components/CandidatesSidebar";
import DocumentLoader from "@/components/ModalWindow/ModalBodyes/DocumentLoader";
import { debounce } from "@/services/helpers";
import router from "../../router";
const route = useRoute();

const store = useStore();
const importFormats = [
  ".xls",
  ".xlsx",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];
const role = store.getters["auth/role"];
const filteringOptions = ref([]);
const isImportShow = ref(false);
const showImportModal = ref(false);
const showModalLoader = ref(false);
const activeFilter = ref("all");
const showPagination = ref(true);
const listCardElement = ref();
const importResultData = ref();
const page = ref(1);
const totalItems = ref(0);
const loaderMessage = ref("");
const headerScheme = [
  {
    label: "ФИО",
    value: "fio",
    sortable: true,
    width: "120",
  },
  {
    label: "Возраст",
    value: "age",
    sortable: true,
    width: "80",
  },
  {
    label: "Статус",
    value: "status",
    sortable: false,
    width: "80",
  },
  {
    label: "Должность",
    value: "specialities",
    sortable: false,
    width: "105",
  },
  {
    label: "Опыт",
    value: "experience",
    sortable: true,
    width: "60",
  },
  {
    label: "Город",
    value: "residence",
    sortable: true,
    width: "105",
  },
  {
    label: "Метки",
    value: "labels",
    sortable: false,
    width: "90",
  },
];

const candidatesListScheme = {
  full_name: {
    key: "full_name",
    type: "string",
    class: "text",
    width: "120",
  },
  age: {
    key: "age",
    type: "string",
    class: "text",
    width: "80",
  },
  status: {
    key: "status",
    type: "split_horizontal",
    class: "text",
    width: "80",
    additionalKey: "days",
  },
  specialities: {
    key: "specialities",
    type: "string",
    class: "text",
    width: "105",
  },
  work_experience: {
    key: "work_experience",
    type: "string",
    class: "text",
    width: "60",
  },
  residence: {
    key: "residence",
    type: "string",
    class: "text",
    width: "105",
  },
  labels: {
    key: "labels",
    type: "array",
    class: "text",
    width: "90",
  },
};

const genderMap = {
  male: "Мужчина",
  female: "Женщина",
};

const candidatesList = ref([]);

const filterOptions = ref();
const paginationFilter = ref({
  "page[offset]": 0,
  "page[limit]": 24,
});

const candidateFilters = ref({
  "with-duplicates": null,
  vacancy_id: null,
  search: "",
  sort: "",
  "status[]": [],
  age_from: null,
  age_to: null,
  localities: null,
  "localities[]": null,
  specialities: [],
  "experience[from]": null,
  "experience[to]": null,
  "created_at[from]": "",
  "created_at[to]": "",
  "age[from]": null,
  "age[to]": null,
  source: [],
  labels: [],
});
const sidebarFilters = ref({
  vacancy_id: null,
  age_from: null,
  age_to: null,
  localities: null,
  specialities: [],
  experience_from: null,
  experience_to: null,
  created_at_from: "",
  created_at_to: "",
  "experience[from]": null,
  "experience[to]": null,
  "created_at[from]": "",
  "created_at[to]": "",
  source: [],
  labels: [],
  status: [],
});

const sortOrder = ref({});
if (route.meta.role !== "HR") textNoContent.value = "Список задач пуст";

const idToLabelsMap = ref({
  all: "Все",
  doubles: "Дубли",
});

if (role === "HR") {
  filteringOptions.value = [
    {
      id: "all",
      label: "Все",
    },
    {
      id: "doubles",
      label: "Дубли",
    },
  ];
} else if (role === "APPROVING_PERSON") {
  filteringOptions.value = [
    {
      id: "all",
      label: "Все",
    },
    {
      id: "is_new",
      label: "Необработанные",
    },
    {
      id: "is_deadline",
      label: "Срок истёк",
    },
    {
      id: "is_worked_out",
      label: "Отработанные",
    },
  ];
} else {
  filteringOptions.value = [
    {
      id: "all",
      label: "Все",
    },
    {
      id: "is_new",
      label: "Новые",
    },
    {
      id: "is_deadline",
      label: "Дедлайн",
    },
  ];
}
const element = ref();
const observer = ref();
const importedFile = ref();
const observerCallback = function (entries, observer) {
  const entry = entries[0];
  if (entry?.isIntersecting) {
    if (candidatesList.value?.length >= 24) {
      debounceFetchMoreCandidates(true);
    }
    observer.unobserve(element.value);
  }
};

const onSortOrderUpdate = (val) => {
  candidateFilters.value.sort = val;
};

const onSegmentSelect = (val) => {
  activeFilter.value = val;
  if (val === "all") {
    candidateFilters.value["with-duplicates"] = null;
  } else {
    candidateFilters.value["with-duplicates"] = 1;
  }
};

const clearSearch = () => {
  candidateFilters.value.search = "";
};
const onCandidatesSearch = (val) => {};
const onCandidateAdd = () => {
  router.push("/candidates/new");
};

const onActionClick = () => {
  isImportShow.value = !isImportShow.value;
};

const onImportClick = (e) => {
  const file = e?.target?.files?.[0];
  if (file && importFormats.includes(file.type)) {
    importedFile.value = file;
    loaderMessage.value = "Ожидайте, документ обрабатывается";
    showModalLoader.value = true;
    candidates
      .importExcel(importedFile.value)
      .then((res) => {
        if (res?.data) {
          importResultData.value = res?.data;
          showModalLoader.value = false;
          showImportModal.value = true;
        }
      })
      .finally(() => (showModalLoader.value = false));
  }
};

const onSaveAll = () => {
  showImportModal.value = false;
  loaderMessage.value = "Ожидайте, кандидаты добавляются";
  showModalLoader.value = true;
  candidates
    .saveImport(importedFile.value)
    .then((res) => {
      if (res) {
        fetchMoreCandidates();
      }
    })
    .finally(() => (showModalLoader.value = false));
};

const onSaveOnlyNew = () => {
  showImportModal.value = false;
  loaderMessage.value = "Ожидайте, кандидаты добавляются";
  showModalLoader.value = true;
  candidates
    .saveOnlyNew(importedFile.value)
    .then((res) => {
      if (res) {
        fetchMoreCandidates();
      }
    })
    .finally(() => (showModalLoader.value = false));
};
const addExtension = () => {
  new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, 1000);
  }).then(() => {
    //to extensions
  });
};

const fetchMoreCandidates = (isAdd = false) => {
  if (isAdd) {
    paginationFilter.value["page[offset]"] += 24;
  } else {
    paginationFilter.value["page[offset]"] = 0;
  }

  const tempFilters = {
    ...candidateFilters.value,
    ...sidebarFilters.value,
    ...paginationFilter.value,
  };
  const sortString = tempFilters.sort;

  tempFilters["localities[]"] = sidebarFilters.value?.localities;
  tempFilters["age[from]"] = sidebarFilters.value?.age_from;
  tempFilters["age[to]"] = sidebarFilters.value?.age_to;
  tempFilters.age_from = null;
  tempFilters.age_to = null;
  tempFilters.created_at_from = null;
  tempFilters.created_at_to = null;
  tempFilters.sort = null;
  tempFilters.experience_from = null;
  tempFilters.experience_to = null;
  tempFilters["created_at[from]"] = sidebarFilters.value?.["created_at[from]"];
  tempFilters["created_at[to]"] = sidebarFilters.value?.["created_at[to]"];

  for (const key in tempFilters) {
    if (Array.isArray(tempFilters[key]) && !tempFilters[key]?.length) {
      tempFilters[key] = null;
    } else if (
      typeof tempFilters[key] === "string" &&
      !tempFilters[key]?.length
    ) {
      tempFilters[key] = null;
    }
  }
  candidates.getCandidates(tempFilters, sortString).then((res) => {
    totalItems.value = res?.meta?.total;

    if (isAdd) {
      if (res?.data && res?.data?.length > 0) {
        candidatesList.value.push(...res?.data);
      } else {
        observer.value.unobserve(element.value);
        return;
      }
    } else {
      candidatesList.value = res?.data;
    }

    nextTick(() => {
      let lastElement = null;
      element.value = document.querySelector(".candidates__card:last-child");
      observer.value = new IntersectionObserver(observerCallback, {
        threshold: 0.5,
      });
      lastElement = element.value;
      if (element.value) observer.value.observe(element.value);
    });
  });
};
const debounceFilterCandidates = debounce(() => {
  candidates.getCandidates(candidateFilters.value).then((res) => {
    if (res?.data) candidatesList.value = res?.data;
  });
}, 400);

const debounceFetchMoreCandidates = debounce(
  (isAdd = false) => fetchMoreCandidates(isAdd),
  400
);

const onOuterImportClick = function (e) {
  if (!e.target.closest(".candidates__header-action") && isImportShow.value) {
    isImportShow.value = false;
  }
};

watch(
  sidebarFilters,
  () => {
    const tempFilters = {
      ...candidateFilters.value,
      ...sidebarFilters.value,
      ...paginationFilter.value,
    };

    const sortString = tempFilters.sort;

    tempFilters["localities[]"] = sidebarFilters.value?.localities;
    tempFilters["age[from]"] = sidebarFilters.value?.age_from;
    tempFilters["age[to]"] = sidebarFilters.value?.age_to;
    tempFilters.age_from = null;
    tempFilters.age_to = null;
    tempFilters.created_at_from = null;
    tempFilters.created_at_to = null;
    tempFilters.experience_from = null;
    tempFilters.experience_to = null;
    tempFilters["created_at[from]"] =
      sidebarFilters.value?.["created_at[from]"];
    tempFilters["created_at[to]"] = sidebarFilters.value?.["created_at[to]"];
    for (const key in tempFilters) {
      if (Array.isArray(tempFilters[key]) && !tempFilters[key]?.length) {
        tempFilters[key] = null;
      } else if (
        typeof tempFilters[key] === "string" &&
        !tempFilters[key]?.length
      ) {
        tempFilters[key] = null;
      }
    }
    candidateFilters.value = tempFilters;
  },
  { deep: true, immediate: false }
);

watch(
  candidateFilters,
  () => {
    if (candidateFilters.value?.search?.length > 0) {
      paginationFilter.value["page[offset]"] = 0;
    }
    debounceFetchMoreCandidates();
  },
  { deep: true, immediate: false }
);

onMounted(() => {
  document.addEventListener("click", onOuterImportClick);

  fetchMoreCandidates();

  candidates.getFilters().then((res) => {
    filterOptions.value = res?.data;
    filterOptions.value.status = res?.data?.statuses?.map((option, index) => ({
      id: option,
      label: option,
    }));
    filterOptions.value.source = res?.data?.source?.map((option, index) => ({
      id: option,
      label: option,
    }));
    filterOptions.value.specialities = res?.data?.specialities?.map(
      (option, index) => ({ id: option, label: option })
    );
    filterOptions.value.labels = res?.data?.labels?.map((option, index) => ({
      id: option,
      label: option,
    }));
  });
});

onUnmounted(() => {
  document.removeEventListener("click", onOuterImportClick);
});
</script>

<style scoped lang="scss">
.candidates {
  padding-bottom: 57px;
  &__modal {
    &_import {
      :deep(.modal__body) {
        width: 352px;
      }
    }
    &_loader {
      :deep(.modal__body) {
        width: 352px;
      }
    }
  }

  &__breadcrumbs {
    margin-top: 24px;
  }

  &__header {
    margin-top: 32px;

    &_vacancy {
      margin-top: 16px;
    }
  }

  &__header-action {
    cursor: pointer;
  }

  &__header-drop-item {
    position: relative;
    display: flex;
    align-items: center;
    padding: 4px 8px;
    cursor: pointer;
  }

  &__header-drop-text {
    margin-left: 4px;
    color: #0d5fcb;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    text-align: right;
  }

  &__row {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
  }

  &__segmented-control {
    &::v-deep(.v-filtration__filter) {
      width: 268px;
      margin-left: 0;
    }
    :deep(.v-filtration__filter_active .v-filtration__label) {
      font-weight: 600;
    }
  }

  &__search {
    width: 544px;

    &::v-deep(.companies__search-clear) {
      height: 20px !important;
    }

    &::v-deep(.v-input__input) {
      &::placeholder {
        color: $dark-blue;
        opacity: 1;
      }
    }

    :deep(.v-input__input::placeholder) {
      color: #566a83 !important;
    }
  }

  &__content {
    width: 832px;
  }

  &__cards {
    margin-left: 32px;
    width: 100%;
    height: calc(100% - 206px);
    margin-top: 12px;
    display: flex;
    flex-direction: column;
  }

  &__card {
    width: 100%;

    &:not(:first-child) {
      margin-top: 8px;
    }
  }

  &__no-content {
    margin-left: 32px;
    width: 100%;
    height: calc(
      100vh - 56px - 32px - 34px - 24px - 48px - 32px - 40px - 57px - 57px
    );
    max-height: calc(475px - 40px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__no-content-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 352px;
    font-size: 24px;
    font-weight: 600;
    line-height: 29.05px;
    text-align: center;
    color: $dark;
  }
  &__no-content-title {
    margin-bottom: 12px;
    font-size: 24px;
    font-weight: 600;
    line-height: 29.05px;
    text-align: center;
    color: $dark;
  }
  &__no-content-description {
    font-size: 16px;
    font-weight: 400;
    line-height: 19.36px;
    text-align: center;
    color: $dark;
  }

  &__pagination {
    position: relative;
    width: 100%;
    height: 48px;
    margin-top: 32px;
    display: flex;
    justify-content: center;
  }

  &__pagination-btn {
    width: 156px;
    padding: 0 10px;
    border-radius: 8px;

    &:active {
      background-color: #0d5fcb80 !important;
      color: $blue !important;
    }
  }

  &__pagination-pag {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__approval-history-modal {
    &::v-deep(.modal__body) {
      width: 626px;
    }
  }

  &__content-wrapper {
    margin-top: 32px;
    display: flex;
  }

  &__sidebar {
    width: 256px;
  }

  &__header-sort {
    margin-left: 32px;
  }

  &__file-import {
    visibility: hidden;
    display: inline-block;
    outline: none;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    appearance: none;
    cursor: pointer;
  }
}

.card-item {
  &__column {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: fit-content;
    & > div {
      flex: 0 0 auto;
    }
  }
  &__status-text {
    font-size: 13px;
    font-weight: 400;
    line-height: 15.73px;
    text-align: left;
    color: $gray;
  }
  &__tags {
    padding: 2px 2px;
    border: 1px solid #566a83;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;
    line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: fit-content;
    max-width: 100%;
  }
  &__specialities {
    line-clamp: 1;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__residence {
    display: -webkit-box;
    line-clamp: 3;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.item-name {
  &__dublicate {
    padding: 2px 8px;
    border-radius: 4px;
    color: $dark;
    background-color: $light-gray-1;
    font-size: 11px;
    font-weight: 400;
    line-height: 13.31px;
    text-align: left;
  }
}
</style>
