<template>
  <div class="candidate-vacancy">
    <div class="candidate-vacancy__header">
      <h2 class="candidate-vacancy__title">Добавление к вакансии</h2>

      <div class="candidate-vacancy__close" @click.prevent="$emit('close')">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 5L5 15"
            stroke="#333333"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M5 5L15 15"
            stroke="#333333"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
    <div class="candidate-vacancy__body">
      <div class="candidate-vacancy__search-block">
        <img
          class="candidate-vacancy__search-icon"
          src="@/assets/images/icons/search.svg"
        />
        <VDefaultInput
          v-model="search"
          @update:modelValue="vacancySearch"
          placeholder="Введите название вакансии"
          class="candidate-vacancy__search-input"
        />
      </div>
      <div class="candidate-vacancy__vacancies-list">
        <ul class="vacancies-list">
          <li
            class="vacancies-list__item"
            v-for="(vacancy, index) in filteredVacancy"
            :key="index"
            @click="addResumeTo(vacancy)"
          >
            <div class="vacancies-list__item-date">
              {{ vacancy?.created_at }}
            </div>
            <h3 class="vacancies-list__item-title">{{ vacancy?.title }}</h3>
            <div class="vacancies-list__item-tasks">
              Активные задачи: {{ vacancy?.active_job_responses_count }}
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, computed } from "vue";
const emit = defineEmits(["close", "addResumeTo"]);
const props = defineProps({
  vacancies: {
    type: Array,
    required: true,
  },
});
const search = ref("");
const filteredVacancy = computed(() => {
  return props.vacancies.filter((item) =>
    item?.title?.toLowerCase().includes(search.value.toLowerCase())
  );
});

const addResumeTo = (vacancy) => {
  emit("addResumeTo", vacancy);
  setTimeout(() => {
    emit("close");
  }, 20);
};

const vacancySearch = () => {};
</script>
<style lang="scss" scoped>
.candidate-vacancy {
  position: relative;
  display: flex;
  flex-direction: column;

  &__modal {
    &__resume {
      :deep(.modal__body) {
        width: 832px;
      }
    }
  }

  &__header {
    border-bottom: 1px solid $light-gray-1;
    padding: 13px 24px 12px;
  }
  &__body {
    padding: 24px;
  }

  &__close {
    position: absolute;
    top: 12px;
    right: 16px;
    cursor: pointer;

    & svg path {
      stroke: #566a83;
    }
  }

  &__icon {
    height: 56px;
    width: 56px;
    flex: 0 0 auto;
    align-self: center;
    margin-bottom: 16px;
  }

  &__title {
    font-size: 16px;
    font-weight: 600;
    line-height: 19.36px;
    text-align: center;
  }

  &__search-block {
    margin-bottom: 24px;
    position: relative;
  }

  &__search-icon {
    position: absolute;
    top: 12px;
    left: 12px;
  }

  &__search-input {
    width: 448px;
    border: 1px solid $light-gray-1;

    &::v-deep(.v-input__input) {
      padding-left: 48px;

      &::placeholder {
        color: $dark-blue;
        opacity: 1;
      }
    }
  }

  &__button {
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
  &__vacancies-list {
    height: 456px;
  }
}

.vacancies-list {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  max-height: 456px;
  overflow-y: auto;
  scrollbar-width: none;
  &__item {
    display: flex;
    flex-direction: column;
    flex: 0 0 calc(50% - 12px);
    max-width: calc(50% - 12px);
    height: fit-content;
    padding: 16px;
    border: 1px solid $light-gray-1;
    border-radius: 16px;
    cursor: pointer;
  }
  &__item-date {
    margin-bottom: 10px;
    color: $gray;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
  }
  &__item-title {
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
    text-align: left;
  }
  &__item-tasks {
    color: $dark-blue;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.52px;
    text-align: left;
  }
}
</style>
