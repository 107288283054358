<template>
  <div class="v-filtration">
    <div
      class="v-filtration__filter"
      v-for="item in items"
      :key="item.id"
      @click="onFilterClick(item.id)"
      :class="{ 'v-filtration__filter_active': item.id == value }"
    >
      <span class="v-filtration__label">{{ item?.label }}</span>
      <span
        v-if="item?.count"
        :class="[
          'v-filtration__count',
          { [colorsClassMap(item.id)]: !!item.id },
          { 'v-filtration__count_one': item?.count == 1 },
        ]"
        >{{ item?.count }}</span
      >
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  modelValue: [String, Number],
  items: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(["update:modelValue"]);

const value = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
});

const onFilterClick = (id) => {
  value.value = id;
};
const colorsClassMap = computed(() => (id) => {
  if (id === "is_new") {
    return "color_blue";
  } else if (id === "is_deadline") {
    return "color_red";
  } else if (id === "is_worked_out") {
    return "color_gray";
  }
  return false;
});
</script>

<style scoped lang="scss">
.v-filtration {
  height: 48px;
  padding: 4px;
  border-radius: 12px;
  display: flex;
  background-color: $light-gray-1;
  color: $gray;
  user-select: none;

  &__filter {
    position: relative;
    width: 205.6px;
    height: 100%;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    transition: 0.3s;

    &:not(:first-child) {
      margin-left: 4px;
    }

    &_active {
      background-color: #fff;
      color: $dark;
      font-weight: 600;
    }
  }

  &__label {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    color: #8f9cac;
  }

  &__count {
    display: flex;
    justify-content: center;
    flex-shrink: 0;
    margin-left: 4px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    text-align: center;
    background-color: var(--color);
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;

    &_one {
      padding: 0 1px 0 0;
    }
  }
  &__filter_active {
    & .v-filtration__label {
      color: $dark;
    }
  }

  &__incoming-count {
    margin-left: 4px;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
    text-align: center;
    color: var(--color);
  }
}

.color {
  &_blue {
    --color: #0166b8;
  }

  &_red {
    --color: #dd4646;
  }

  &_gray {
    --color: #8f9cac;
  }
}
</style>
