<template>
  <div class="candidate-edit">
    <ModalWindow
      v-if="showDublicateModal"
      @close="showDublicateModal = false"
      class="candidate-modal"
    >
      <CandidateDublicateAttention
        @close="showDublicateModal = false"
        @replaceSuccess="onReplaceSuccess"
      />
    </ModalWindow>
    <div class="candidate-edit__wrapper container">
      <a @click="$router.back()" class="candidate-edit__breadcrumbs">
        <img src="@/assets/images/icons/back-arrow.svg" alt="arrow" />
        <p>Карточка кандидата</p>
      </a>

      <div class="candidate-edit__header">
        <h1 class="candidate-edit__title main-title">
          {{ $route.meta.name }}
        </h1>
      </div>
      <div class="candidate-edit__body">
        <div class="candidate-edit__form">
          <div class="candidate-edit__form-header">
            <h2 class="candidate-edit__form-title">Данные кандидата</h2>
            <VSelect
              class="candidate-edit__form-header-select"
              placeholder="Выберите статус"
              :options="statusOptions"
              v-model="form.status"
            />
          </div>

          <section class="candidate-edit__form-section">
            <h3 class="candidate-edit__form-section-title">Личные данные</h3>
            <div class="candidate-edit__form-row">
              <div class="candidate-edit__form-field">
                <VCustomInput
                  class="candidate-edit__input"
                  placeholder="ФИО*"
                  :error="v$.full_name.$error"
                  v-model="form.full_name"
                />
                <p
                  class="candidate-edit__form-field-error"
                  v-if="v$.full_name.$error"
                >
                  Заполните обязательное поле
                </p>
              </div>

              <VSegmentedControl
                :items="genderOptions"
                v-model="form.gender"
                class="candidate-edit__switch"
                id="gender"
                @update:modelValue="(val) => onGenderUpdate(val)"
              />
            </div>
            <div class="candidate-edit__form-row">
              <div class="candidate-edit__form-field">
                <VCustomInput
                  class="candidate-edit__input"
                  placeholder="Дата рождения*"
                  v-model="form.birthdate"
                  v-mask="'00.00.0000'"
                />
                <p
                  class="candidate-edit__form-field-error"
                  v-if="v$.birthdate.$error"
                >
                  Заполните обязательное поле
                </p>
              </div>
              <div class="candidate-edit__form-field">
                <VSearchSelect
                  class="candidate-edit__select"
                  placeholder="Город проживания*"
                  :error="v$.residence.$error"
                  :items="resultsResidence"
                  @selectItem="onSelectResidence"
                  @input="debouncedSearchResidence"
                  v-model="form.residence.name"
                />
                <p
                  class="candidate-edit__form-field-error"
                  v-if="v$.residence.$error"
                >
                  Заполните обязательное поле
                </p>
              </div>
            </div>
          </section>
          <section class="candidate-edit__form-section">
            <h3 class="candidate-edit__form-section-title">
              Контактные данные
            </h3>
            <div class="candidate-edit__form-row">
              <div class="candidate-edit__form-field">
                <VCustomInput
                  class="candidate-edit__input candidate-edit__input_disabled"
                  placeholder="Телефон*"
                  v-model="form.phone"
                  v-mask="'+0(000)-000-00-00'"
                  type="text"
                  name="mask"
                />
                <p
                  class="candidate-edit__form-field-error"
                  v-if="v$.phone.$error"
                >
                  Заполните обязательное поле
                </p>
              </div>
              <div class="candidate-edit__form-field">
                <VCustomInput
                  class="candidate-edit__select"
                  placeholder="Почта"
                  v-model="form.email"
                />
                <p
                  class="candidate-edit__form-field-error"
                  v-if="v$.email.$error"
                >
                  Введите корректный email
                </p>
              </div>
            </div>
          </section>
          <section class="candidate-edit__form-section">
            <h3 class="candidate-edit__form-section-title">
              Дополнительные данные
            </h3>
            <div class="candidate-edit__form-row">
              <VCustomInput
                class="candidate-edit__select"
                placeholder="Опыт работы"
                v-model="form.work_experience"
              />
              <VSelect
                class="candidate-edit__select"
                placeholder="Источник"
                :options="sourceOptions"
                v-model="form.source"
              />
            </div>
            <div class="candidate-edit__form-row_full">
              <VTagsInputSelect
                class="candidate-edit__select"
                placeholder="Должность"
                :options="specialtyOptions"
                :isWithoutCheckmarks="true"
                v-model="form.specialities"
              />
            </div>
            <div class="candidate-edit__form-row_full">
              <VTagsInputSelect
                class="candidate-edit__select"
                placeholder="Метки"
                :options="labelsOptions"
                :isWithoutCheckmarks="true"
                v-model="form.labels"
              />
            </div>
          </section>
          <section class="candidate-edit__form-section">
            <h3 class="candidate-edit__form-field-title">
              Комментарий по кандидату
            </h3>
            <p class="candidate-edit__form-field-label">
              Не более 2000 символов
            </p>
            <div class="candidate-edit__form-textarea">
              <VEditor
                placeholder="Напишите комментарий"
                v-model="form.comment"
                id="description"
                class="basic-information__editor"
              />
            </div>
          </section>
        </div>
      </div>

      <div class="candidate-edit__btns">
        <VButton
          label="Отменить"
          bg="#E4E7EE"
          bgHover="#BBC4CD"
          color="#1E3959"
          colorHover="#1E3959"
          class="candidate-edit__btn"
          link
          :to="`/candidates/${route?.params?.id}`"
        />

        <VButton
          label="Сохранить"
          bg="#0D5FCB"
          color="#fff"
          class="candidate-edit__btn"
          @click="saveCandidate"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, onMounted, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import candidates from "@/api/candidates";
import common from "@/api/common";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import VTagsInputSelect from "@/components/UI/VTagsInputSelect";
import CandidateDublicateAttention from "@/components/ModalWindow/ModalBodyes/CandidateDublicateAttention";
import useOffsetTop from "@/hooks/getOffsetTopScroll";
import { debounce } from "./../../services/helpers";

const router = useRouter();
const route = useRoute();
const customRouteLink = ref(`${route.matched[0].path}/${route.params.id}`);
const editId = route.params.id;
const isShowModal = ref(false);
const showDublicateModal = ref(false);
const showReplaceModal = ref(false);
const form = reactive({
  full_name: "",
  gender: "male",
  birthdate: "",
  residence: {
    name: "",
  },
  residence_id: null,
  phone: "",
  email: "",
  specialty: "",
  work_experience: "",
  status: "",
  labels: "",
  source: "",
  comment: `<p></p>`,
});

const rules = computed(() => ({
  full_name: { required },
  birthdate: { required },
  residence: { required },
  phone: { required },
  email: { email },
}));

const v$ = useVuelidate(rules, form);

const genderOptions = [
  {
    id: "male",
    label: "Мужчина",
  },
  {
    id: "female",
    label: "Женщина",
  },
];

const statusOptions = ref([]);
const sourceOptions = ref([]);
const labelsOptions = ref([]);
const specialtyOptions = ref([]);
const resultsResidence = ref([]);

const onGenderUpdate = (val) => {
  form.gender = val;
};
const saveCandidate = async () => {
  const validate = await v$.value.$validate();

  if (!validate) {
    useOffsetTop();
    return;
  }

  const { phone, ...filteredForm } = form;

  candidates.updateCandidate(route.params?.id, filteredForm).then((res) => {
    router.push(`/candidates/${route.params?.id}`);
  });
};

const searchResidence = async () => {
  const res = await common.searchResidence(form.residence.name);
  if (res?.data)
    resultsResidence.value = res?.data.map((item) => {
      return {
        ...item,
        name: item?.regionDescription
          ? `${item?.regionDescription} ${item?.name}`
          : `${item?.name}`,
      };
    });
};

const onSelectResidence = (item) => {
  form.residence_id = item?.id;
  form.residence.name = item?.name;
};

const debouncedSearchResidence = debounce(() => searchResidence(), 400);

const onReplaceSuccess = () => {
  showReplaceModal.value = true;
};

onMounted(() => {
  candidates.getCandidate(route.params?.id).then((res) => {
    if (res?.data) {
      for (const key in res?.data) {
        if (key === "residence") {
          form.residence.name = res?.data?.residence?.name
            ? `${
                res?.data?.residence?.regionDescription
                  ? res?.data?.residence?.regionDescription + ", "
                  : ""
              }${res?.data?.residence?.name}`
            : "";
        } else {
          form[key] = res?.data[key];
        }
      }
      form.residence_id = res?.data?.residence?.id;
    }
  });

  candidates.getStatuses().then((res) => {
    statusOptions.value = res?.data?.statuses?.map((option, index) => ({
      id: option,
      label: option,
    }));
  });

  candidates.getSources().then((res) => {
    sourceOptions.value = res?.data?.sources?.map((option, index) => ({
      id: option,
      label: option,
    }));
  });

  candidates.getLabels().then((res) => {
    labelsOptions.value = res?.data?.labels;
  });

  candidates.getSpecialities().then((res) => {
    specialtyOptions.value = res?.data?.specialities;
  });
});
</script>

<style scoped lang="scss">
.candidate-modal {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 40px 24px 24px;

  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }

  &__icon {
    height: 56px;
    width: 56px;
    flex: 0 0 auto;
    align-self: center;
    margin-bottom: 16px;
  }

  &__title {
    margin-bottom: 8px;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    line-height: 24.2px;
  }

  &__description {
    margin-bottom: 24px;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.8px;
  }
  &__button {
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
}

.candidate-edit {
  padding-bottom: 45px;

  :deep(.modal__body) {
    width: 352px;
  }

  :deep(.v-filtration) {
    border-radius: 4px;
    background-color: $light;
  }
  :deep(.v-filtration__filter) {
    width: 186px;
    border-radius: 4px;
  }

  &__wrapper {
    padding-top: 14px;
  }

  &__breadcrumbs {
    display: flex;
    align-items: center;
    color: $gray;
    text-decoration: none;
    cursor: pointer;
    user-select: none;

    p {
      margin-left: 4px;
      font-size: 14px;
      font-weight: 400;
    }

    img {
      width: 20px;
    }
  }

  &__header {
    margin-top: 8px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__form {
    padding: 24px;
    background-color: #fff;
    border-radius: 16px;
  }

  &__input {
    width: 100%;
    &_disabled {
      pointer-events: none;
      user-select: none;
      color: $dark-blue;
      :deep(.v-input__input) {
        color: $dark-blue;
      }
    }
  }

  &__form-field {
    :deep(.v-input) {
      width: 100%;
    }
  }

  &__form-field-error {
    margin-top: 4px;
    font-size: 14px;
    color: $red;
  }

  &__form-header {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #e4e7ee;
  }

  &__form-header-select {
    flex: 0 1 224px;
    :deep(.v-select__header) {
      width: 224px;
      height: 44px;
      background-color: #f0f2f4;
    }
    :deep(.v-select__header-palceholder) {
      color: $gray;
    }

    :deep(.v-select__header_selected .v-select__header-palceholder) {
      display: none;
    }

    :deep(.v-select__header_selected .v-select__header-value) {
      top: 16px;
    }
  }

  &__form-title {
    flex: 1 1 50%;
    font-size: 20px;
    font-weight: 500;
  }
  &__form-section {
    margin-top: 20px;
  }
  &__form-section-title {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    text-align: left;
  }

  &__form-field-title {
    margin-bottom: 4px;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    text-align: left;
  }

  &__form-field-label {
    margin-bottom: 16px;
    color: $gray;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
  }

  &__form-textarea {
    :deep(.v-editor__editor) {
      height: 240px;
    }
  }

  &__form-row {
    display: flex;
    gap: 16px;
    margin-bottom: 16px;
    & div {
      flex: 0 1 calc(50% - 8px);
    }
    &_full {
      margin-bottom: 16px;
    }
  }

  &__btns {
    width: 100%;
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
  }

  &__btn {
    &:first-child {
      width: 42.307%;

      &:active {
        background-color: $gray !important;
      }
    }

    &:last-child {
      width: 53.846%;

      &:active {
        background-color: #0a3489 !important;
      }
    }
  }
}
</style>
