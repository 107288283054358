<template>
  <div class="candidate-response">
    <div class="candidate-response__close" @click.prevent="$emit('close')">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15 5L5 15"
          stroke="#333333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5 5L15 15"
          stroke="#333333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <img
      class="candidate-response__icon"
      src="@/assets/images/icons/check-mark-green.svg"
    />
    <h2 class="candidate-response__title">Отклик создан</h2>
    <p class="candidate-response__description">
      Кандидит прикреплен к вакансии, отклик успешно создан
    </p>
    <VButton
      label="К отклику"
      bg="#0D5FCB"
      color="#fff"
      class="candidate-response__button"
      @click="toResponse"
    />
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";
const emit = defineEmits(["close"]);
const router = useRouter();

const toResponse = (id) => {
  router.push("/responses");
};
</script>
<style lang="scss" scoped>
.candidate-response {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 40px 24px 24px;

  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }

  &__icon {
    height: 56px;
    width: 56px;
    flex: 0 0 auto;
    align-self: center;
    margin-bottom: 16px;
  }

  &__title {
    margin-bottom: 8px;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    line-height: 24.2px;
  }

  &__description {
    margin-bottom: 24px;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.8px;
  }
  &__button {
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
}
</style>
